const en = {
  "login-button": "Login",
  "login-h2": "Please log in",
  "login-password": "Password",
  "login-forgot-password": "Forgot your password?",
  "login-register": "New Credentials",
  "login-create-account": "Sign up",
  "login-new-to-aino": "New to Aino.io?",
  "password-recovery-title": "Password Recovery",
  "password-recovery-email": "E-mail",
  "password-recovery-submit-button": "Submit",
  "password-recovery-success": "A recovery e-mail has been sent to the given address.",
  "password-recovery-failed": "Recovery e-mail sending failed.",
  "password-reset-title": "Reset your password",
  "password-reset-1": "Password",
  "password-reset-2": "Again",
  "password-reset-submit-button": "Submit",
  "password-reset-success": "Your password has been changed. Please, log in.",
  "password-reset-login": "To Login Page",
  "password-reset-failed": "Password reset failed, please try again.",
  "instance-from": "From",
  "instance-to": "To",
  "instance-identifier": "Identifier",
  "instance-search": "Search",
  "instance-timestamp-error": "Please use correct timestamp format",
  "instance-timestamp-later-error": "To timestamp must be later than From timestamp",
  "instance-filter-failure": "Failure",
  "instance-filter-all": "Select all",
  "instance-filter-success": "Success",
  "instance-filter-unknown": "Unknown",
  "instance-filter-select-at-least-one-operation": "Please select at least one operation.",
  "instance-filter-select-at-least-one-integrationSegment": "Please select at least one integration segment.",
  "instance-filter-select-at-least-one-application": "Please select at least one application.",
  "instance-filter-select-at-least-one-idType": "Please select at least one id type.",
  "instance-filter-select-at-least-one-metadataName": "Please select at least one metadata name.",
  "instance-no-transactions": "No transactions recorded.",
  "instance-no-errors": "No errors in any transaction.",
  "instance-no-warnings": "No warnings in any transaction.",
  "instance-clear-errors": "Clear Errors",
  "instance-clear-warnings": "Clear Warnings",
  "instance-errors-clearing-failed": "Failed to clear errors.",
  "instance-transaction-capacity": "of capacity",
  "instance-stats__last-trx-timestamp": "of searchable transactions and flows",
  "instance-stats__last-summary-timestamp": "of historical transaction and flow statistics available",
  "instance-no-transactions-available": "Transaction data does not exist for selected time period",
  "instance-no-flows-available": "Flow data does not exist for selected time period",
  "instance-search-help": "Use quotes if the identifier contains spaces.",
  logout: "Logout",
  "main-navigation-home": "Home",
  "logged-in-as": "Logged in as",
  "date-and-time": "Date and time",
  "apikey-description-input": "New API key description",
  "api-key-management-title": "Transaction API access",
  "api-key-management-description": "Description",
  "api-key-management-apiKey": "API Key",
  "api-key-management-manage": "Manage",
  "api-key-management-enable": "Enable",
  "api-key-management-disable": "Disable",
  "api-key-management-disabled": "(Disabled)",
  "api-key-management-creation-failure": "Failed to create new API Key.",
  "api-key-management-toggle-failure": "Failed to toggle the API key's disabled status.",
  "api-key-management-toggle-success": "Success! The change will be applied within the next 30 minutes.",
  "api-key-management-import": "Allow Import",
  "api-key-management-export": "Allow Export",
  "api-key-management-toggle-import-failure": "Failed to toggle the API key's allow import status.",
  "api-key-management-toggle-export-failure": "Failed to toggle the API key's allow export status.",
  "api-key-management-user-group-change-failed": "Failed to changed user group.",
  "connection-lost-error": "Connection lost, please retry later",
  "no-access-error": "No access to this resource",
  "invalid-credentials-error": "Invalid credentials",
  "not-confirmed-login-error": "Account is not confirmed, new confirmation mail is sent to your mail box",
  "api-key-management__description-input-warning": "Warning! The description already exists.",
  "forbidden.title": "Forbidden",
  "forbidden.help-text": "You are not authorized to see something you requested.",
  "forbidden.main-view-link": "Go back to Main page",
  "flash-error.not-logged-in": "Please log in before accessing secure content.",
  "instance-filter-clear": "Clear selections",
  "instance-filter-applications": "Applications",
  "instance-filter-status": "Status",
  "instance-header-overview": "Overview",
  "instance-header-timeline": "Timeline",
  "instance-header-flowtimeline": "Flow Timeline",
  "instance-header-operations": "Operations",
  "instance-header-transactions": "Transactions",
  "instance-header-applications": "Applications",
  "instance-header-alerts": "Alerts",
  "instance-header-errors": "Errors",
  "instance-header-api-access": "API Access",
  "instance-header-export": "Export",
  "instance-header-flow-export": "Flow Export",
  "instance-header-alert-export": "Alert Export",
  "instance-header-users": "Users",
  "instance-header-months-days": "{{months}} months {{days}} days",
  "instance-header-days": "{{days}} days",
  "instance-header-transactions-since": "since {{ts}}",
  "instance-header-flows": "Flows",
  "instance-header-identifiedflows": "Identified Flows",
  "instance-header-linkedflows": "Linked Flows",
  "instance-header-transaction-export": "Transaction Export",
  "instance-analyzed-count": "Analyzed Transactions",
  "instance-transaction-count": "Searchable Transactions",
  "application-stats-volume": "Volume",
  "application-stats-status-ratios": "Status ratios",
  "instance-time-year": "Year",
  "instance-time-month": "Month",
  "instance-time-week": "Week",
  "instance-time-day": "Day",
  "instance-time-hour": "Hour",
  "instance-start-from": "Starting from",
  "instance-time-reset": "Now",
  "confirm-title": "Thank you for verifying your credentials!",
  "confirm-text": "You can now start using Aino.io.",
  "confirm-login": "Go to login",
  "confirm-failed-text": "The verification link you used was invalid.",
  "already-confirmed-title": "You have already confirmed your account.",
  "already-confirmed-text": "Please continue to log in.",
  "dashboard-no-content": "You do not have access to any Instances, yet. Please contact your administrator.",
  "dashboard-pending-invitations": "Pending Invitations",
  "logout-failed": "Logout failed, please try again.",
  "data-not-analyzed": "No analyzed data yet.",
  "timeline-search-placeholder": "Identifier Search",
  "timeline-search-results-not-found": "No results found. Please refine your search.",
  "failed-to-get-flow-volume": "Failed to get flow volume data.",
  "signup-for-newsletter": "Subscribe to monthly newsletter for news, ideas and innovations.",
  "signup-for-tech-newsletter": "Subscribe to technical updates and maintenance notifications.",
  "newsletter-unsubscribe": "You can unsubscribe at any time.",
  "accept-invitation": "Accept",
  "reject-invitation": "Reject",
  "invitation-reponse-failed": "The invitation has been removed. Please contact you administrator.",
  "invitation-reponse-accepted": "Invitation successfully accepted.",
  "invitation-reponse-rejected": "Invitation successfully rejected.",
  "tos-checkbox": "I have read and agree to the Terms of Service",
  "tos-submit": "Submit",
  "timeline-filter-search": "Filter",
  "timeline-transaction-status": "Transaction status",
  "timeline-filter-operations": "Operations",
  "timeline-filter-integrationSegments": "Integration Segments",
  "timeline-filter-applications": "Applications",
  "timeline-filter-idTypes": "Id Types",
  "timeline-filter-metadataNames": "Metadata Names",
  "timeline-enum-filter-placeholder-application": "Start typing application name",
  "timeline-enum-filter-placeholder-operation": "Start typing operation name",
  "timeline-enum-filter-placeholder-idType": "Start typing ID type name",
  "timeline-enum-filter-placeholder-metadataName": "Start typing metadata name",
  "timeline-enum-filter-placeholder-integrationSegment": "Start typing integration segment name",
  "alert-summary-enum-operation": "Selected Operations",
  "alert-summary-enum-application": "Selected Applications",
  "error-details": "Error Details",
  "warning-details": "Warning Details",
  "problematic-relative-failures": "Relative Failures",
  "problematic-failure-volume": "Failure Volume",
  "problematic-failure-rate": "Failure Rate",
  "problematic-relative-failures-text": "{{ratio}}% of all monitored failures",
  "problematic-failure-volume-text": "{{volume}} monitored failures",
  "problematic-failure-rate-text": "{{rate}}%",
  "overview-problematic-applications": "Problematic Applications",
  "overview-problematic-operations": "Problematic Operations",
  "overview-bar-chart-text": '',
  "overview-overall-status-ratios": "6-hour Overview of Monitored Integrations",
  "problematic-application-relative-failures-header": "Applications most involved in transaction failures",
  "problematic-application-failure-volume-header": "Applications with highest transaction failure volume",
  "problematic-application-failure-rate-header": "Applications with highest failure rate",
  "problematic-operation-relative-failures-header": "Operations most involved in transaction failures",
  "problematic-operation-failure-volume-header": "Operations with highest transaction failure volume",
  "problematic-operation-failure-rate-header": "Operations with highest failure rate",
  "overview-no-data-yet": "No data collected yet.",
  "applications-network-title": "Application Network",
  "applications-volumes": "Application Volume",
  "operations-volumes": "Operation Volume",
  "instance-operation": "Operation",
  "applications-no-data": "No data available.",
  "operations-no-data": "No data available.",
  "overview-volume-percentage": "{{value}} Volume",
  "overview-success-percentage": "{{value}}% Success",
  "overview-failure-percentage": "{{value}}% Failure",
  "overview-unknown-percentage": "{{value}}% Unknown",
  "overview-time-text": "From {{startTime}} to {{endTime}}",
  "save-filters-input-placeholder": "Save selected filters...",
  "save-filters-remove-button": "Remove",
  "timeline-loaded-filter": "Loaded filter: {{filter}}",
  "errors-settings-enable": "Enable custom times",
  "error-settings-past-warning": "Minutes in the past to generate a warning:",
  "error-settings-future-warning": "Minutes in the future to generate a warning:",
  "error-settings-past-error": "Minutes in the past to generate an error:",
  "error-settings-future-error": "Minutes in the future to generate an error:",
  "error-settings-saved": "Settings saved.",
  "error-settings-saving-failed": "Failed to save the settings.",
  "error-settings-save": "Save",
  "timeline-max-value-placeholder": "Use a custom scale for the timeline:",
  "flow-steps": "Steps",
  "flow-name": "Flow Name",
  "flow-from-title": "From",
  "flow-to-title": "To",
  "flow-operation-title": "Operation",
  "flow-add-step": "Add Step",
  "flow-save": "Save",
  "flow-cancel": "Cancel",
  "flow-header": "Flow Definition",
  "flow-help":
    "Use the inputs below to define how a certain flow should look like. The definition can then be used in Alerts, for example.",
  "flow-name-input": "Flow name",
  "flows-new-flow": "New Flow Configuration",
  "flows-table-name": "Name",
  "flows-table-start": "Starting Application",
  "flows-table-end": "Ending Application",
  "flows-table-remove": "Remove",
  "flows-table-remove-question": "Really remove the flow configuration?",
  "flows-duplicate-extension": "(Duplicate)",
  "flows-remove-failed": "Failed to remove flow configuration.",
  "flows-remove-success": "Successfully removed the flow configuration.",
  "flows-duplicate-failed": "Failed to duplicate flow configuration.",
  "flows-duplicate-success": "Successfully duplicated the flow configuration.",
  "send-flow-failed": "Failed to create flow configuration.",
  "flow-update": "Update",
  "update-flow-failed": "Flow configuration update failed.",
  "flow-delete-step-button": "Remove",
  "check-duplicate-flow-failed": "Failed to check for duplicates",
  "flow-has-duplicate":
    "There is already a Flow configuration defined with either a same name or a combination of applications or operations.",
  "timeline-show-totals": "Show the totals on the bars",
  "trx-list-save-csv": "Save as csv",
  "trx-list-save-help": "This will save the whole list as a CSV file.",
  "failed-to-get-flows": "Failed to get flows.",
  "failed-save-flows": "Failed to save flows.",
  "failed-to-get-transactions": "Failed to get transactions.",
  "failed-save-transactions": "Failed to save transactions.",
  "toggle-table-to-flow": "Show Flows",
  "toggle-table-to-trx": "Show Transactions",
  "flowtimechart-max-value": "Use custom maximum time for the chart:",
  "flowtimechart-minutes": "minutes",
  "flowtimechart-seconds": "seconds",
  "identified-flow-manage": "Manage",
  "named-flows-load-failed": "Failed to load named flows.",
  "flow-lead-time": "Lead Time"
};

export { en };
