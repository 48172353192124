import { createElmApp } from "../../../../utils/ElmUtils";
import { Elm } from "../../../../../../aino.elmproj";

export class ElmApiAccessController {
  constructor($rootScope, $state, $localStorage, $translate, instance, Restangular) {
    "ngInject";

    createElmApp($rootScope, $state, Elm.Pages.Instance.Admin.ApiAccess.Page, "elm-api-access", {
      token: $localStorage.accessToken || null,
      translations: JSON.stringify($translate.getTranslationTable()),
      instance
    }, Restangular, $localStorage);
  }
}

