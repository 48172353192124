const formFi = {
  "form-input-email": "Your E-mail",
  "form-input-email-invalid": "Invalid E-mail address",
  "form-input-email-again": "Re-enter E-mail",
  "form-input-email-mismatch": "The E-mails do not match.",
  "form-input-name": "Your Full Name",
  "form-field-required": "This field is required.",
  "form-input-name-missing": "Your name is missing.",
  "form-input-password": "Your Password",
  "form-input-password-error": "Password is too short.",
  "form-input-password-too-short": "Password is too short.",
  "form-input-password-min-length": "Minimum length is 8 letters or numbers",
  "form-input-password-too-long": "Salasana on liian pitkä.",
  "form-input-password-too-weak": "Password is too weak.",
  "form-input-password-rating-failed": "Connection error.",
  "rating-strength-weak": "Password not strong enough",
  "rating-strength-strong": "Password strong enough"
};

export { formFi };
