import "./instance.scss";
import moment from "moment";

export class InstanceController {
  constructor(
    $scope,
    $rootScope,
    $translate,
    $state,
    AlertService,
    InstanceService,
    instance,
    enums,
    unresolvedCountObj,
    errorCount
  ) {
    "ngInject";

    this.scope = $scope;
    this.rootScope = $rootScope;
    this.translate = $translate;
    this.alertService = AlertService;
    this.instance = instance;
    this.enums = enums;
    this.unresolvedCountObj = unresolvedCountObj || { unresolvedCount: 0 };
    this.errorCount = errorCount || { errorCount: 0 };
    this.state = $state;

    const interval = setInterval(() => {
      this.updateUnresolvedAlertCount();
    }, moment.duration(5, "minute").asMilliseconds());

    $scope.$on("unauthorized", () => clearInterval(interval));
    $scope.$on("$destroy", () => clearInterval(interval));

    const self = this;

    $scope.$on("refreshInstance", (_, instanceId) => {
      Promise.all([InstanceService.getInstance(instanceId), InstanceService.getEnums(instanceId)])
        .then((resolvedList) => {
          self.instance = resolvedList[0];
          self.enums = resolvedList[1];
          self.scope.$apply();
        })
        .catch((e) => console.log("err", e));
    });

    $scope.$on("refreshEnum", (_, instanceId) =>
      InstanceService.getEnums(instanceId)
        .then((enums) => {
          self.enums = enums;
          self.scope.$apply();
        })
        .catch((e) => console.log("err", e))
    );
  }

  isState(state) {
    return this.state.is(state);
  }

  updateUnresolvedAlertCount() {
    this.alertService
      .getUnresolvedCount(this.instance._id)
      .then((response) => {
        this.unresolvedCountObj.unresolvedCount = response.count;
        this.scope.$apply();
      })
      .catch((e) => {
        console.error(e);
      });
  }

  getTransactionCount() {
    return this.instance.transactionCount <= this.instance.totalTransactionLimit
      ? this.instance.transactionCount
      : this.instance.totalTransactionLimit;
  }

  getTimeText(time) {
    const duration = moment.duration(time, "days");
    const months = duration.asMonths() | 0;
    const days = duration.subtract(months, "months").asDays() | 0;
    return months > 0
      ? this.translate.instant("instance-header-months-days", { months, days })
      : this.translate.instant("instance-header-days", { days });
  }

  getSearchableTransactionTimeText() {
    return this.getTimeText(this.getSearchableTransactionDays());
  }

  getHistoricalStatisticsTimeText() {
    return this.getTimeText(this.getHistoricalStatisticsDays());
  }

  getSinceTimestampText(timestamp) {
    const ts = moment(timestamp).format("ddd DD MMM YYYY");
    return this.translate.instant("instance-header-transactions-since", { ts });
  }

  getSearchableTransactionSinceTimestampText() {
    return this.getSinceTimestampText(this.instance.lastTransactionTimestamp);
  }

  getHistoricalStatisticsSinceTimestampText() {
    return this.getSinceTimestampText(this.instance.lastSummaryTimestamp);
  }

  getDays(timestamp) {
    return timestamp ? moment().diff(timestamp, "days") : 0;
  }

  getSearchableTransactionDays() {
    return this.getDays(this.instance.lastTransactionTimestamp);
  }

  getHistoricalStatisticsDays() {
    return this.getDays(this.instance.lastSummaryTimestamp);
  }
}
