import "./password-input.scss";
import template from "./password-input.html";
import { isNil } from "ramda";

const MIN_PASSWORD_LENGTH = 8;
const MAX_PASSWORD_BYTES = 72;
const MIN_RATE = 3;

export function PasswordInputDirective($rootScope, $log, UserService, observeOnScope, rx) {
  "ngInject";

  return {
    restrict: "E",
    scope: {
      formName: "=",
      inputModel: "=",
      inputName: "=",
      bem: "=",
      labelI18nKey: "=",
      warningI18nKey: "=",
      required: "="
    },
    link: (scope) => {
      scope.inputName = scope.inputName || "password";

      scope.isTooShort = () => scope.inputModel && scope.inputModel.length < MIN_PASSWORD_LENGTH;

      scope.isTooLong = () => scope.inputModel && Buffer.byteLength(scope.inputModel, "utf8") > MAX_PASSWORD_BYTES;

      scope.isTooWeak = () => scope.rating.score < MIN_RATE;

      const ratePassword = (model) => {
        if (isNil(model.newValue) || model.newValue === '') {
          scope.rating = undefined;
          scope.$apply();
          return rx.Observable.empty();
        }

        return rx.Observable.fromPromise(UserService.ratePassword(model.newValue)).catch((err) => {
          $log.debug("Connection error", err);
          $rootScope.flashErrors.push("Failed to get a rating for the password");
        });
      };

      const updateFormValidity = () => {
        scope.formName.$setValidity(
          scope.inputName,
          scope.rating.score >= MIN_RATE && !scope.isTooShort() && !scope.isTooLong()
        );
        scope.$apply();
      };

      observeOnScope(scope, "inputModel")
        .debounce(300)
        .flatMapLatest(ratePassword)
        .subscribe(
          (rating) => {
            scope.rating = rating;
            updateFormValidity();
          },
          (err) => {
            $log.debug("Subscribe error", err);
            $rootScope.flashErrors.push("form-input-password-rating-failed");
          }
        );
    },
    template
  };
}
