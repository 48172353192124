import { BaseService } from "./base.service";
import moment from "moment-timezone";

const FIVE_MINS_AS_MILLIS = moment.duration(5, "minutes").asMilliseconds();

export class TransactionSummaryService extends BaseService {
  constructor(Restangular) {
    "ngInject";
    super();

    this.instance = Restangular.service("instance");
    this.volumeCancelers = {};
    this.searchCancelers = {};
  }

  getTransactionVolumeForDashboard(instanceId, start, end) {
    return super.plainData(
      this.instance
        .one(instanceId)
        .one("transactionsummary")
        .one("volume")
        .customPOST({
          start: moment(closestFiveMinute(start.valueOf())).valueOf(),
          end: moment(closestFiveMinute(end.valueOf())).valueOf(),
          resolution: "5mins",
          tz: moment.tz.guess()
        })
    );
  }

  getApplicationStats(instanceId, startTime, endTime, applications, operations, timeZone) {
    return super.plainData(
      this.instance
        .one(instanceId)
        .one("transactionsummary")
        .one("applicationstats")
        .customPOST({
          start: startTime.format(),
          end: endTime.format(),
          applications,
          operations,
          timeZone
        })
    );
  }

  getOperationStats(instanceId, startTime, endTime, operations, timeZone) {
    return super.plainData(
      this.instance
        .one(instanceId)
        .one("transactionsummary")
        .one("operationstats")
        .customPOST({
          start: startTime.format(),
          end: endTime.format(),
          operations,
          timeZone
        })
    );
  }
}

const closestFiveMinute = (millis) => Math.ceil(millis / FIVE_MINS_AS_MILLIS) * FIVE_MINS_AS_MILLIS;
