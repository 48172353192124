const accountEn = {
  "account-address": "Business Address",
  "account-business-title": "Business",
  "account-button": "Sign up for Aino.io",
  "account-company-name": "Company Name",
  "account-country": "Country",
  "account-create-fail": "Account creation failed. Please check that you don\"t already have an account in Aino.io",
  "account-create-success": "Account successfully created.",
  "account-created-text": "You will receive an e-mail with a confirmation link for finishing the account creation.",
  "account-created-title": "Thank you for creating new account",
  "account-details-title": "Your account details",
  "account-email": "Billing E-mail",
  "account-owner-title": "Owner",
  "account-privacy-policy": "Privacy Policy",
  "account-title": "Create your free account",
  "account-sub-title": "Start your free trial. No credit card required.",
  "account-tos": "I have read and agree to the Terms of Service and Privacy Policy",
  "account-vat": "Business ID or VAT (if available)",
  "eur-month": "€/month",
  "plan-select-title": "Choose a plan",
  "plan-select-text-pre": "The plan can be changed later. For more details, see our",
  "plan-select-link": "plans and pricing",
  "plan-select-text-post": "information."
};

export { accountEn };

