const alertEn = {
  "alert-configuration-management-title": "Alert Configuration",
  "alert-configuration-name-input": "You must enter a name for the alert",
  "alert-configuration-management-name-input-warning": "Warning! The name already exists.",
  "alert-configuration-management-interval-title": "How often the alert conditions are checked",
  "alert-configuration-creation-error": "Failed to create Alert Configuration!",
  "alert-configuration-toggle-success": "Successfully toggled Alert Configuration",
  "alert-configuration-toggle-error": "Failed to toggle Alert Configuration!",
  "alert-configuration-management-name": "Name of the alert",
  "alert-configuration-management-manage": "Manage",
  "alert-configuration-management-enable": "Enable",
  "alert-configuration-management-disable": "Disable",
  "alert-configuration-management-disabled": "(Disabled)",
  "alert-configuration-management-identifier": "Identifier found in transactions",
  "alert-configuration-management-no-identifier": "Identifier not found in transactions",
  "alert-configuration-conditions": "Trigger Conditions",
  "alert-configuration-management-status": "Statuses found in transactions",
  "alert-configuration-management-applications": "Applications found in transactions",
  "alert-configuration-management-operations": "Operations found in transactions",
  "alert-configuration-management-users": "Who should get notified when an alert is triggered",
  "alert-configuration-management-enable-users": "Enable All",
  "alert-configuration-management-disable-users": "Disable All",
  "alert-configuration-management-remove": "Remove",
  "alert-configuration-management-remove-question": "Really remove configuration and alerts?",
  "alert-configuration-users-toggle-error": "Failed to toggle users.",
  "alert-configuration-management-customemail-input": "E-mail",
  "alert-configuration-management-add-customemail": "Add",
  "alert-configuration-management-remove-customemail": "Remove",
  "alert-configuration-management-duplicate-customemail-email": "The e-mail already exists.",
  "alert-configuration-removal-error": "alert configuration removal failed",
  "alert-configuration-create-new": "Create new alert configuration",
  "alert-configuration-management-trigger-title": "Trigger this alert if there are",
  "alert-configuration-trigger-gt-label": "More than",
  "alert-configuration-trigger-lt-label": "Less than",
  "alert-configuration-trigger-gt-input": "Number of transactions (0 or more)",
  "alert-configuration-trigger-lt-input": "Number of transactions (1 or more)",
  "alert-configuration-trigger-text": "transactions that meet the criteria",
  "alert-configuration-trigger-flow-text": "flows that meet the criteria",
  "alert-configuration-trigger-gt-zero": "At least one transaction that meets the criteria",
  "alert-configuration-trigger-none": "No transactions that meet the criteria",
  "alert-configuration-trigger-custom": "Custom threshold",
  "alert-configuration-trigger-invalid": "Please, check your trigger values",
  "alert-configuration-management-limit-reached": "You have reached your plan's active alert limit!",
  "alert-configuration-select-timezone": "Select new timezone",
  "alert-configuration-current-timezone": "Current timezone:",
  "alert-configuration-change-timezone": "Change",
  "alerts-groups-name": "Name",
  "alerts-groups-latest": "Latest",
  "alerts-groups-unconfirmed-total": "Unconfirmed/Total",
  "alerts-groups-show-all": "Show alerts",
  "alerts-groups-hide-all": "Hide alerts",
  "alerts-timestamp": "Timestamp",
  "alerts-configuration-name": "Alert Name",
  "alerts-transactions": "Transactions",
  "alerts-no-alerts":
    "You have no alerts. It is recommended to have them to automatically monitor for any failures and undesired behavior.",
  "alert-title": "Alert",
  "alerts-alert-ack": "Acknowledge",
  "alerts-flow-ack": "Acknowledge Flow",
  "alerts-ack-failed": "Failed to acknowledge the alert",
  "alerts-ack-trx-failed": "Failed to acknowledge the transaction",
  "alerts-ack-flow-failed": "Failed to acknowledge the flow",
  "alerts-ack-all-failed": "Failed to acknowledge all alerts",
  "alerts-alert-ack-all": "Acknowledge All",
  "alerts-alert-ack-all-question": "Really acknowledge all?",
  "alerts-get-alert-error": "Failed to get the alert",
  "alerts-get-transactions-error": "Failed to get the transcations for this alert",
  "alert-ack-all": "Acknowledge whole Alert",
  "alert-ack-all-question": "This will mark the whole Alert as acknowledged. Are you sure?",
  "alerts-pagination-failed": "Failed to get alerts.",
  "alert-configuration-custom-email-placeholder": "Add recipient e-mail",
  "alert-configuration-management-selected-users": "E-mails:",
  "alert-configuration-no-emails": "No e-mails added for this alert.",
  "alert-configuration-no-applications-selected": "Please, select at least one application.",
  "alert-configuration-no-operations-selected": "Please, select at least one operation.",
  "alert-configuration-only-one-id": "Only one identifier can be used.",
  "alert-configuration-custom-user": "Add a custom recipient's e-mail:",
  "alert-configuration-user-filter-placeholder": "Filter instance users",
  "alert-configuration-required": "(required)",
  "alert-configuration-email-sending": "When to send e-mails",
  "alert-configuration-email-sending-every": "Send e-mail notification from every alert",
  "alert-configuration-email-sending-change":
    "Send e-mail notification only when the state of the alert changes (no alert to alert and vice versa)",
  "alert-configuratio-when-email-is-sent": "Do you want to receive e-mails only when the state of the alert changes",
  "alert-configuration-management-active-alerts": "Active Alerts: {{current}} / {{max}}",
  "alerts-interval": "Interval: {{interval}}",
  "alerts-identifiers": "Identifier: {{ids}}",
  "alerts-status": "Status: {{status}}",
  "alerts-applications": "Applications: {{applications}}",
  "alerts-operations": "Operations: {{operations}}",
  "alerts-all-operations": "All operations are included.",
  "alerts-all-applications": "All applications are included.",
  "alert-configuration-Mon": "Mon",
  "alert-configuration-Tue": "Tue",
  "alert-configuration-Wed": "Wed",
  "alert-configuration-Thu": "Thu",
  "alert-configuration-Fri": "Fri",
  "alert-configuration-Sat": "Sat",
  "alert-configuration-Sun": "Sun",
  "alert-configuration-schedule": "Schedule",
  "alert-configuration-active-days": "Active Days",
  "alert-configuration-active-hours": "Active Hours",
  "alert-configuration-active-hours-enabled": "Enabled:",
  "alert-configuration-timezone": "Timezone",
  "alerts-job-timeframe": "The alert was triggered within this time period",
  "alerts-load-failed": "Failed to load Alert Configurations"
};
export { alertEn };
