import { forEach, isEmpty, isNil } from "lodash";
import { Tree } from "./tree";

export function createGraph(parent, transactions, selectedTransactionId, width, height) {
  const graphs = prepareGraphs(transactions, selectedTransactionId);
  const graphHeight = height / graphs.length;

  forEach(parent.children, child => {
    if (!isNil(child)) {
      child.remove()
    }
  });

  graphs.forEach(graph => {
    const svg = Tree(graph, {
      width,
      height: isNaN(graphHeight) ? undefined : graphHeight,
      label: d => d.name
    });
    const node = svg.node();
    parent.append(node);
  });
}

function prepareGraphs(transactions, selectedTransactionId) {
  function reducer(graphs, trx) {
    const selected = trx.ids.includes(selectedTransactionId);

    if (isEmpty(graphs)) {
      graphs.push({
        name: trx.from,
        children: [{ name: trx.to, children: [], selected }],
        selected
      });
      return graphs;
    }

    const graph = graphs[graphs.length - 1];
    if (graph.name === trx.from) {
      const child = graph.children.find(c => c.name === trx.to);
      if (child) {
        child.selected = selected || child.name === trx.to;
      } else {
        graph.children.push({
          name: trx.to,
          children: [],
          selected
        });
      }

      return graphs;
    }

    const child = findLastChild(graph.children, trx);
    if (child) {
      child.selected = child.selected || selected;
      const grandChild = child.children.find(c => c.name === trx.to);
      if (grandChild) {
        grandChild.selected = grandChild.selected || selected;
      } else {
        child.children.push({
          name: trx.to,
          children: [],
          selected
        });
      }
    } else {
      graphs.push({
        name: trx.from,
        children: [{ name: trx.to, children: [], selected }],
        selected
      });
    }

    return graphs;
  }

  function mapper(target, trx, index) {
    if (index === 0) {
      target.push({
        from: trx.from,
        to: trx.to,
        ids: [trx._id.toString()]
      });

      return target;
    }

    const prevTrx = target[target.length - 1];
    if (prevTrx.from === trx.from && prevTrx.to === trx.to) {
      prevTrx.ids.push(trx._id.toString());
    } else {
      target.push({
        from: trx.from,
        to: trx.to,
        ids: [trx._id.toString()]
      });
    }

    return target;
  }

  return transactions
    .reduce(mapper, [])
    .reduce(reducer, []);
}

function findLastChild(children, trx) {
  for (let i = children.length - 1; i >= 0; i--) {
    const child = children[i];

    const found = findLastChild(child.children, trx);
    if (found) {
      return found;
    }

    if (child.name === trx.from) {
      return child;
    }
  }
}
