import { Elm } from "../../../../../aino.elmproj";
import { createElmApp, subscribe } from "../../../utils/ElmUtils";
import moment from "moment-timezone";
import { isEmpty } from "lodash";
import { createGraph } from "../../../utils/treeGraph";

export class ElmLinkedFlowController {
  constructor($rootScope, $state, $filter, $translate, $transition$, instance, enums, Restangular, $localStorage) {
    "ngInject";

    const app = createElmApp($rootScope, $state, Elm.Pages.Instance.LinkedFlow.Page, "elm-linked-flow", {
      translations: JSON.stringify($translate.getTranslationTable()),
      instance,
      token: $localStorage.accessToken || null,
      enums,
      urlParams: $transition$.params(),
      timeZone: moment.tz.guess(),
      linkedFlowId: $transition$.params().id
    }, Restangular, $localStorage);

    subscribe(app, "showApplicationFlowGraph", ({ width, height, transactions, domId }) => {
      if (!isEmpty(transactions)) {
        const parent = document.getElementById(domId);
        if (parent) {
          createGraph(parent, transactions, undefined, width, height);
        } else {
          console.error(`No element found with id: ${domId}`);
        }
      }
    });
  }
}
