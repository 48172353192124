export function runBlock(
  $log,
  $rootScope,
  $localStorage,
  Restangular,
  $state,
  preFetchedUser,
  UserService,
  ErrorInterceptorService,
  $location,
  rx,
  $transitions
) {
  "ngInject";

  $log.debug("runBlock, preFetchedUser", preFetchedUser);

  rx.Observable.$on = (scope, onExpression) =>
    rx.Observable.create((observer) => {
      function listener(event, ...args) {
        observer.onNext({ event, args });
      }

      return scope.$on(onExpression, listener);
    });

  $rootScope.flashErrors = [];
  $rootScope.flashMessages = [];
  $rootScope.previousStates = [];
  $rootScope.previousStateParams = [];

  $rootScope.user = preFetchedUser;

  if ($localStorage.accessToken) {
    Restangular.setDefaultHeaders({ Authorization: "JWT " + $localStorage.accessToken });
  }

  Restangular.setErrorInterceptor((response) =>
    ErrorInterceptorService.handleResponse(response)
  );

  $rootScope.stateLoading = false;

  $transitions.onStart({}, (transition) => {
    const stateService = transition.router.stateService;
    const toState = transition.to();
    const toParams = transition.params();

    $rootScope.previousStates.push(transition.from());
    $rootScope.previousStateParams.push(transition.params("from"));
    $rootScope.stateLoading = true;

    if ($rootScope.user && $rootScope.user.showTos && toState.name !== "tos") {
      return stateService.target("tos", { toState, toParams }, { reload: true });
    }

    if (toState.name === "tos" && !$rootScope.user.owner) {
      return stateService.target("dashboard", null, { reload: true });
    }

    if (toState.name === "account" && $rootScope.user) {
      return stateService.target("dashboard", null, { reload: true });
    }

    // Redirect
    if (toState.redirectTo) {
      return stateService.target(toState.redirectTo, toParams, { reload: true });
    }

    if (toState.name === "login") {
      // user is prefetched so this should be enough(we do not need to fetch user again at this point)
      if ($rootScope.user) {
        $rootScope.stateLoading = false;
        return stateService.target("dashboard", null, { reload: true });
      }
    } else {
      if (toState.data && toState.data.authenticated && !UserService.isAuthenticated()) {
        $rootScope.flashErrors.push("flash-error.not-logged-in");
        return stateService.target("login", { toState, toParams }, { reload: true });
      }
    }
  });

  $transitions.onSuccess({}, () => {
    //$('.main-content').animate({ scrollTop: 0 }, 200);
    $rootScope.stateLoading = false;
  });

  $transitions.onError({}, (transition) => {
    console.error(transition.error());
    $rootScope.stateLoading = false;
  });

  $state.defaultErrorHandler(function (error) {
    $log.debug("stateChangeError", error);
    $rootScope.stateLoading = false;
  });

  $rootScope.$on("$locationChangeSuccess", function () {
    $rootScope.$emit("locationChangeSuccess", $location.search());
  });
}
