import { BaseService } from "./base.service";

export class InstanceService extends BaseService {
  constructor(Restangular, $localStorage) {
    "ngInject";
    super();

    this.instance = Restangular.service("instance");
    this.localStorage = $localStorage;
  }

  getInstance(instanceId) {
    return super.plainData(this.instance.one(instanceId).get());
  }

  getEnums(instanceId) {
    return super.plainData(this.instance.one(instanceId).one("enum").get());
  }

  getErrors(instanceId) {
    return super.plainData(this.instance.one(instanceId).one("data-api-error").get());
  }

  getErrorsWithBatchId(instanceId, batchId) {
    return super.plainData(this.instance.one(instanceId).one("data-api-error").one(batchId).get());
  }

  getErrorCount(instanceId) {
    return super.plainData(this.instance.one(instanceId).one("data-api-error").one("count").get());
  }

  getWarnings(instanceId) {
    return super.plainData(this.instance.one(instanceId).one("data-api-warning").get());
  }

  getWarningsWithBatchId(instanceId, batchId) {
    return super.plainData(this.instance.one(instanceId).one("data-api-warning").one(batchId).get());
  }

  getInvitedUsers(instanceId) {
    return super.plainData(this.instance.one(instanceId).one("invitation").get());
  }

  inviteUser(instanceId, emails) {
    return super.plainData(this.instance.one(instanceId).one("invitations").customPOST({ emails }));
  }

  reinviteUser(instanceId, invitationId, email) {
    return super.plainData(this.instance.one(instanceId).one("invitation").one(invitationId).one("reinvite")
      .customPUT({ email }));
  }

  removeUser(instanceId, userId) {
    return this.instance.one(instanceId).one("user").one(userId).remove();
  }

  removeInvitation(instanceId, invitationId) {
    return super.plainData(this.instance.one(instanceId).one("invitation").one(invitationId).remove());
  }

  getOperationMaps(instanceId) {
    return super.plainData(this.instance.one(instanceId).one("operationMap").get());
  }
}
