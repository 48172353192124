import { Elm } from "../../../../../aino.elmproj";
import { createElmApp, subscribe } from "../../../utils/ElmUtils";
import moment from "moment-timezone";
import toMoment from "../../../utils/MomentUtils";
import { chain, forEach, isEmpty } from "lodash";
import * as d3 from "d3";
import { EnumUtils } from "../../../utils/EnumUtils";

let highlightNode;

const defaultNodeColor = "#28c8c8";
const defaultLinkColor = "#b4b0b6";
const nominalStroke = 1.5;
const minZoom = 1;
const maxZoom = 2;

const containerWidth = (element) => element.offsetWidth;

const containerHeight = (element) => element.offsetHeight;

export class ElmApplicationsController {
  constructor($scope, $rootScope, $state, $transition$, $location, $localStorage, $translate, instance, enums, operationMaps,
              Restangular) {
    "ngInject";

    const flags = {
      token: $localStorage.accessToken || null,
      translations: JSON.stringify($translate.getTranslationTable()),
      instance,
      enums,
      startTime: $transition$.params().startTime
        ? toMoment($transition$.params().startTime).format()
        : moment()
          .startOf("month")
          .format(),
      timeNow: moment().format(),
      urlParams: $transition$.params(),
      timeZone: moment.tz.guess(),
      operationMaps
    };

    const app = createElmApp($rootScope, $state, Elm.Pages.Instance.Applications.Page, "elm-applications",
      flags, Restangular, $localStorage);

    subscribe(app, "setSearchParams", (searchParams) => {
      $scope.$apply(() => $location.search(createSearchObject(searchParams)));
    });

    subscribe(app, "removeApplicationNetwork", () => {
      const parent = document.getElementById("applications-network-graph");
      if (parent) {
        forEach(parent.children, child => child.remove());
      }
    });

    subscribe(app, "showApplicationNetwork", ({ statuses, response }) => {
      const applicationStats = JSON.parse(response);
      const graphData = {
        nodes: [],
        links: []
      };

      function calculateVolume(stat) {
        if (isEmpty(statuses)) {
          return stat.volume;
        }
        const success = statuses.includes("success") ? stat.success : 0;
        const failure = statuses.includes("failure") ? stat.failure : 0;
        const unknown = statuses.includes("unknown") ? stat.unknown : 0;
        return success + failure + unknown;
      }

      const maxVolume = applicationStats
        ? applicationStats.reduce((max, statsRow) => {
          const volume = calculateVolume(statsRow);
          return volume > max ? volume : max;
        }, 0)
        : 0;

      graphData.nodes = chain(applicationStats)
        .flatMap((statsRow) => [
          {
            size: 10,
            id: statsRow._id.from,
            type: "circle",
            name: EnumUtils.getApplicationName(statsRow._id.from, enums)
          },
          { size: 10, id: statsRow._id.to, type: "circle", name: EnumUtils.getApplicationName(statsRow._id.to, enums) }
        ])
        .uniqBy("id")
        .value();

      graphData.links = applicationStats
        ? applicationStats.map((statsRow) => ({
          source: statsRow._id.from,
          target: statsRow._id.to,
          volume: calculateVolume(statsRow)
        }))
        : [];

      const parent = document.getElementById("applications-network-graph");
      if (parent) {
        forEach(parent.children, child => child.remove());

        const svg = ForceGraph(graphData, {
          width: containerWidth(parent),
          height: containerHeight(parent),
          nodeFill: defaultNodeColor,
          linkStroke: defaultLinkColor,
          linkStrokeWidth: (d) => nominalStroke + d.volume / maxVolume * nominalStroke * 5
        });
        parent.append(svg);
      } else {
        console.error("No element found with id: applications-network-graph");
      }
    });

    function createSearchObject({ startTime, operations, applications, resolution, status, selectedTab }) {
      return {
        startTime,
        operations: operations ? operations.join(",") : null,
        applications: applications ? applications.join(",") : null,
        resolution,
        status: status ? status.join(",") : null,
        selectedTab
      };
    }
  }
}

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/force-directed-graph
function ForceGraph({
                      nodes, // an iterable of node objects (typically [{id}, …])
                      links // an iterable of link objects (typically [{source, target}, …])
                    }, {
                      nodeId = d => d.id, // given d in nodes, returns a unique identifier (string)
                      nodeGroup, // given d in nodes, returns an (ordinal) value for color
                      nodeGroups, // an array of ordinal values representing the node groups
                      nodeFill = "currentColor", // node stroke fill (if not using a group color encoding)
                      nodeRadius = 5, // node radius, in pixels
                      nodeStrength,
                      linkSource = ({ source }) => source, // given d in links, returns a node identifier string
                      linkTarget = ({ target }) => target, // given d in links, returns a node identifier string
                      linkStroke = "#999", // link stroke color
                      linkStrokeOpacity = 0.6, // link stroke opacity
                      linkStrokeWidth = 1.5, // given d in links, returns a stroke width in pixels
                      linkStrokeLinecap = "round", // link stroke linecap
                      linkStrength,
                      colors = d3.schemeTableau10, // an array of color strings, for the node groups
                      width = 640, // outer width, in pixels
                      height = 400 // outer height, in pixels
                    } = {}) {
  // Compute values.
  const N = d3.map(nodes, nodeId).map(intern);
  const LS = d3.map(links, linkSource).map(intern);
  const LT = d3.map(links, linkTarget).map(intern);
  const G = nodeGroup == null ? null : d3.map(nodes, nodeGroup).map(intern);
  const W = typeof linkStrokeWidth !== "function" ? null : d3.map(links, linkStrokeWidth);
  const L = typeof linkStroke !== "function" ? null : d3.map(links, linkStroke);

  // Replace the input nodes and links with mutable objects for the simulation.
  nodes = d3.map(nodes, (n) => ({ id: n.id, name: n.name }));
  links = d3.map(links, (_, i) => ({ source: LS[i], target: LT[i] }));

  // Compute default domains.
  if (G && nodeGroups === undefined) nodeGroups = d3.sort(G);

  // Construct the scales.
  const color = nodeGroup == null ? null : d3.scaleOrdinal(nodeGroups, colors);

  // Construct the forces.
  const forceNode = d3.forceManyBody();
  const forceLink = d3.forceLink(links)
    .id(({ index: i }) => N[i])
    .distance(60);
  if (nodeStrength !== undefined) forceNode.strength(nodeStrength);
  if (linkStrength !== undefined) forceLink.strength(linkStrength);

  const simulation = d3.forceSimulation(nodes)
    .force("link", forceLink)
    .force("charge", forceNode)
    .force("center", d3.forceCenter())
    .on("tick", ticked);

  const svg = d3.create("svg")
    .attr("width", width)
    .attr("height", height)
    .attr("viewBox", [-width / 2, -height / 2, width, height])
    .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
    .attr("font-family", "sans-serif")
    .attr("font-size", 12)
    .style("cursor", "move");

  const link = svg.append("g")
    .attr("stroke", typeof linkStroke !== "function" ? linkStroke : null)
    .attr("stroke-opacity", linkStrokeOpacity)
    .attr("stroke-width", typeof linkStrokeWidth !== "function" ? linkStrokeWidth : null)
    .attr("stroke-linecap", linkStrokeLinecap)
    .selectAll("line")
    .data(links)
    .join("line");

  if (W) link.attr("stroke-width", ({ index: i }) => W[i]);
  if (L) link.attr("stroke", ({ index: i }) => L[i]);

  const node = svg.append("g")
    .selectAll("g")
    .data(nodes)
    .enter()
    .append("g");

  node.append("circle")
    .attr("r", nodeRadius)
    .attr("fill", nodeFill);

  node.append("text")
    .text((d) => d.name)
    .attr("x", 8)
    .attr("y", "0.31em");

  let focusNode = null;

  node
    .on("mouseover", () => node.style("cursor", "pointer"))
    .on("mousedown", (event, d) => {
      event.stopPropagation();

      focusNode = d;
      if (!highlightNode) {
        node.style("cursor", "pointer");
      }
    })
    .on("mouseout", () => exitHighlight(focusNode, node));

  d3.select(window).on("mouseup", () => {
    focusNode = null;

    if (!highlightNode) {
      exitHighlight(focusNode, node);
    }
  });

  function dragstarted(event) {
    if (!event.active) simulation.alphaTarget(0.3).restart();
    event.subject.fx = event.subject.x;
    event.subject.fy = event.subject.y;
  }

  function dragged(event) {
    event.subject.fx = event.x;
    event.subject.fy = event.y;
  }

  function dragended(event) {
    if (!event.active) simulation.alphaTarget(0);
    event.subject.fx = null;
    event.subject.fy = null;
  }

  const drag_handler = d3.drag()
    .on("start", dragstarted)
    .on("drag", dragged)
    .on("end", dragended);

  drag_handler(node);

  const zoom = d3.zoom().scaleExtent([minZoom, maxZoom]);

  zoom.on("zoom", (event) => {
    svg.attr("transform", event.transform);
  });

  svg.call(zoom);

  function intern(value) {
    return value !== null && typeof value === "object" ? value.valueOf() : value;
  }

  function ticked() {
    link
      .attr("x1", d => d.source.x)
      .attr("y1", d => d.source.y)
      .attr("x2", d => d.target.x)
      .attr("y2", d => d.target.y);

    node.attr("transform", d => `translate(${d.x}, ${d.y})`);
    node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
  }

  return Object.assign(svg.node(), { scales: { color } });
}

const exitHighlight = (focusNode, svg) => {
  highlightNode = null;
  if (focusNode === null) {
    svg.style("cursor", "move");
  }
};
