const commonEn = {
  "common-details": "Details",
  from: "from",
  to: "to",
  create: "Create",
  duplicate: "Duplicate",
  edit: "Edit",
  update: "Update",
  cancel: "Cancel",
  "common-ok": "Ok",
  "common-close": "Close",
  "common-search": "Search",
  "back-to-login": "Already have credentials? Go to login.",
  "common-email": "E-mail",
  "common-emails": "E-mails",
  "common-manage": "Manage",
  "common-yes": "Yes",
  "common-no": "No",
  "common-name": "Name",
  "common-inviter": "Inviter",
  "common-status": "Status",
  "common-invitation-time": "Invitation Time",
  "common-settings": "Settings"
};

export { commonEn };
