import { createElmApp } from "../../utils/ElmUtils";
import { Elm } from "../../../../aino.elmproj";

export class ElmRegisterController {
  constructor($scope, $rootScope, $state, $transition$, $translate, $localStorage, Restangular, invitation) {
    "ngInject";

    createElmApp($rootScope, $state, Elm.Pages.Register.Page, "elm-register-view", {
      translations: JSON.stringify($translate.getTranslationTable()),
      invitation
    }, Restangular, $localStorage);
  }
}
