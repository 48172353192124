import template from "./network-graph.html";
import { chain, forEach } from "lodash";
import * as d3 from "d3";

let highlightNode;

const defaultNodeColor = "#28c8c8";
const defaultLinkColor = "#b4b0b6";
const nominalStroke = 1.5;
const minZoom = 1;
const maxZoom = 8;

const containerWidth = (element) => element[0].firstChild.offsetWidth;

const containerHeight = (element) => element[0].firstChild.offsetHeight;

export function NetworkGraphDirective(observeOnScope) {
  "ngInject";

  return {
    restrict: "E",
    scope: {
      applicationStats: "<",
      applicationName: "&"
    },
    link: (scope, element) => {
      const render = () => {
        const graphData = {
          nodes: [],
          links: [],
          scope
        };

        const maxVolume = scope.applicationStats
          ? scope.applicationStats.reduce((max, statsRow) => (statsRow.volume > max ? statsRow.volume : max), 0)
          : 0;

        graphData.nodes = chain(scope.applicationStats)
          .flatMap((statsRow) => [
            { size: 10, id: statsRow._id.from, type: "circle" },
            { size: 10, id: statsRow._id.to, type: "circle" }
          ])
          .uniqBy("id")
          .value();

        graphData.links = scope.applicationStats
          ? scope.applicationStats.map((statsRow) => ({
            source: statsRow._id.from,
            target: statsRow._id.to,
            volume: statsRow.volume
          }))
          : [];

        const parent = document.getElementById("network-graph");
        if (parent) {
          forEach(parent.children, child => child.remove());

          const svg = ForceGraph(graphData, {
            width: containerWidth(element),
            height: containerHeight(element),
            nodeFill: defaultNodeColor,
            linkStroke: defaultLinkColor,
            linkStrokeWidth: (d) => nominalStroke + d.volume / maxVolume * nominalStroke * 5
          });
          parent.append(svg);
        } else {
          console.error("No element found with id: network-graph");
        }
      };

      observeOnScope(scope, "applicationStats")
        .debounce(100)
        .subscribe(render);
    },
    template
  };
}

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/force-directed-graph
function ForceGraph({
                      nodes, // an iterable of node objects (typically [{id}, …])
                      links, // an iterable of link objects (typically [{source, target}, …])
                      scope
                    }, {
                      nodeId = d => d.id, // given d in nodes, returns a unique identifier (string)
                      nodeGroup, // given d in nodes, returns an (ordinal) value for color
                      nodeGroups, // an array of ordinal values representing the node groups
                      nodeFill = "currentColor", // node stroke fill (if not using a group color encoding)
                      nodeRadius = 5, // node radius, in pixels
                      nodeStrength,
                      linkSource = ({ source }) => source, // given d in links, returns a node identifier string
                      linkTarget = ({ target }) => target, // given d in links, returns a node identifier string
                      linkStroke = "#999", // link stroke color
                      linkStrokeOpacity = 0.6, // link stroke opacity
                      linkStrokeWidth = 1.5, // given d in links, returns a stroke width in pixels
                      linkStrokeLinecap = "round", // link stroke linecap
                      linkStrength,
                      colors = d3.schemeTableau10, // an array of color strings, for the node groups
                      width = 640, // outer width, in pixels
                      height = 400 // outer height, in pixels
                    } = {}) {
  // Compute values.
  const N = d3.map(nodes, nodeId).map(intern);
  const LS = d3.map(links, linkSource).map(intern);
  const LT = d3.map(links, linkTarget).map(intern);
  const G = nodeGroup == null ? null : d3.map(nodes, nodeGroup).map(intern);
  const W = typeof linkStrokeWidth !== "function" ? null : d3.map(links, linkStrokeWidth);
  const L = typeof linkStroke !== "function" ? null : d3.map(links, linkStroke);

  // Replace the input nodes and links with mutable objects for the simulation.
  nodes = d3.map(nodes, (_, i) => ({ id: N[i] }));
  links = d3.map(links, (_, i) => ({ source: LS[i], target: LT[i] }));

  // Compute default domains.
  if (G && nodeGroups === undefined) nodeGroups = d3.sort(G);

  // Construct the scales.
  const color = nodeGroup == null ? null : d3.scaleOrdinal(nodeGroups, colors);

  // Construct the forces.
  const forceNode = d3.forceManyBody();
  const forceLink = d3.forceLink(links)
    .id(({ index: i }) => N[i])
    .distance(60);
  if (nodeStrength !== undefined) forceNode.strength(nodeStrength);
  if (linkStrength !== undefined) forceLink.strength(linkStrength);

  const simulation = d3.forceSimulation(nodes)
    .force("link", forceLink)
    .force("charge", forceNode)
    .force("center", d3.forceCenter())
    .on("tick", ticked);

  const svg = d3.create("svg")
    .attr("width", width)
    .attr("height", height)
    .attr("viewBox", [-width / 2, -height / 2, width, height])
    .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
    .attr("font-family", "sans-serif")
    .attr("font-size", 12)
    .style("cursor", "move");

  const link = svg.append("g")
    .attr("stroke", typeof linkStroke !== "function" ? linkStroke : null)
    .attr("stroke-opacity", linkStrokeOpacity)
    .attr("stroke-width", typeof linkStrokeWidth !== "function" ? linkStrokeWidth : null)
    .attr("stroke-linecap", linkStrokeLinecap)
    .selectAll("line")
    .data(links)
    .join("line");

  if (W) link.attr("stroke-width", ({ index: i }) => W[i]);
  if (L) link.attr("stroke", ({ index: i }) => L[i]);

  const node = svg.append("g")
    .selectAll("g")
    .data(nodes)
    .enter()
    .append("g");

  node.append("circle")
    .attr("r", nodeRadius)
    .attr("fill", nodeFill);

  node.append("text")
    .text((d) => scope.applicationName({ id: d.id }))
    .attr("x", 8)
    .attr("y", "0.31em");

  let focusNode = null;

  node
    .on("mouseover", () => node.style("cursor", "pointer"))
    .on("mousedown", (event, d) => {
      event.stopPropagation();

      focusNode = d;
      if (!highlightNode) {
        node.style("cursor", "pointer");
      }
    })
    .on("mouseout", () => exitHighlight(focusNode, node));

  d3.select(window).on("mouseup", () => {
    focusNode = null;

    if (!highlightNode) {
      exitHighlight(focusNode, node);
    }
  });

  function dragstarted(event) {
    if (!event.active) simulation.alphaTarget(0.3).restart();
    event.subject.fx = event.subject.x;
    event.subject.fy = event.subject.y;
  }

  function dragged(event) {
    event.subject.fx = event.x;
    event.subject.fy = event.y;
  }

  function dragended(event) {
    if (!event.active) simulation.alphaTarget(0);
    event.subject.fx = null;
    event.subject.fy = null;
  }

  const drag_handler = d3.drag()
    .on("start", dragstarted)
    .on("drag", dragged)
    .on("end", dragended);

  drag_handler(node);

  const zoom = d3.zoom().scaleExtent([minZoom, maxZoom]);

  zoom.on("zoom", (event) => {
    svg.attr("transform", event.transform);
  });

  svg.call(zoom);

  function intern(value) {
    return value !== null && typeof value === "object" ? value.valueOf() : value;
  }

  function ticked() {
    link
      .attr("x1", d => d.source.x)
      .attr("y1", d => d.source.y)
      .attr("x2", d => d.target.x)
      .attr("y2", d => d.target.y);

    node.attr("transform", d => `translate(${d.x}, ${d.y})`);
    node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
  }

  return Object.assign(svg.node(), { scales: { color } });
}

const exitHighlight = (focusNode, svg) => {
  highlightNode = null;
  if (focusNode === null) {
    svg.style("cursor", "move");
  }
};
