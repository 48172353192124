import { createElmApp } from "../../utils/ElmUtils";
import { Elm } from "../../../../aino.elmproj";

export class ElmPasswordResetController {
  constructor($state, $rootScope, $transition$, $translate, $localStorage, Restangular) {
    "ngInject";

    createElmApp($rootScope, $state, Elm.Pages.Passwordreset.Page, "elm-pwd-reset-view", {
      token: $transition$.params().token,
      translations: JSON.stringify($translate.getTranslationTable()),
    }, Restangular, $localStorage);
  }

  resetPassword(email) {
    this.loading = true;
    this.userService
      .resetPassword(email, this.token)
      .then(() => {
        this.loading = false;
        this.success = true;
      })
      .catch(() => {
        this.loading = false;
        this.rootScope.flashErrors.push("password-reset-failed");
      });
  }
}
