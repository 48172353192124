import "./passwordrecovery.scss";
import { Elm } from "../../../../aino.elmproj";
import { createElmApp } from "../../utils/ElmUtils";
import moment from "moment-timezone";

export function ElmPasswordRecoveryController($translate, $state, $rootScope, Restangular, $localStorage) {
  "ngInject";

  createElmApp($rootScope, $state, Elm.Main, "elm-passwordrecovery", {
    page: "Passwordrecovery",
    translations: JSON.stringify($translate.getTranslationTable()),
    token: null,
    timeNow: moment().valueOf(),
    timeZone: moment.tz.guess()
  }, Restangular, $localStorage);
}
