import { Elm } from "../../../../../aino.elmproj";
import { createElmApp } from "../../../utils/ElmUtils";
import { find, omit, pick } from "lodash";

export class ElmWarningController {
  constructor($rootScope, $state, $filter, $translate, $transition$, instance, warnings, Restangular, $localStorage) {
    "ngInject";

    const warning = findBatch(warnings, $transition$.params().batchId);

    createElmApp($rootScope, $state, Elm.Pages.Instance.WarningPage, "elm-warning", {
      translations: JSON.stringify($translate.getTranslationTable()),
      instance,
      warnings: getTransactionWarnings(warning, $transition$.params().index),
      transaction: $filter("json")(getTransactionDetails(warning, $transition$.params().index))
    }, Restangular, $localStorage);
  }
}

function findBatch(warnings, batchId) {
  return find(warnings, (warning) => warning.batchId === batchId);
}

function getTransactionDetails(warning, index) {
  return omit(warning.transactions[index], "warnings");
}

function getTransactionWarnings(warning, index) {
  return pick(warning.transactions[index], "warnings").warnings;
}
