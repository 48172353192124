export function routerInstanceAdminConfig($stateProvider) {
  "ngInject";

  $stateProvider
    .state("instance.apiAccess", {
      url: "/api-access",
      views: {
        content: {
          template: require("../../features/instance/admin/api-access/api-access.html").default,
          controller: "ApiAccessController",
          controllerAs: "ctrl"
        }
      },
      resolve: {
        admin: ($state, instance) => {
          if (!instance.sessionUserIsCustomershipAdmin && !instance.sessionUserIsInstanceAdmin &&
            !instance.userGroupInstance.allowApiKeys) {
            return $state.go("forbidden");
          }
        }
      },
      data: { authenticated: true }
    })
    .state("instance.users", {
      url: "/users",
      views: {
        content: {
          template: require("../../features/instance/admin/users/users.html").default,
          controller: "UsersController",
          controllerAs: "usersCtrl"
        }
      },
      resolve: {
        admin: ($state, instance) => {
          if (!instance.sessionUserIsCustomershipAdmin && !instance.sessionUserIsInstanceAdmin) {
            return $state.go("forbidden");
          }
        },

        invitedUsers: (InstanceService, $transition$) =>
          InstanceService.getInvitedUsers($transition$.params().instanceId),

        instance: (InstanceService, $transition$) => InstanceService.getInstance($transition$.params().instanceId)
      },
      data: { authenticated: true }
    });
}
