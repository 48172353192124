import { Elm } from "../../../../../aino.elmproj";
import { createElmApp, subscribe } from "../../../utils/ElmUtils";
import { isEmpty } from "lodash";
import moment from "moment-timezone";
import { createGraph } from "../../../utils/treeGraph";

export class ElmIdentifiedFlowController {
  constructor($scope,
              $rootScope,
              $state,
              $transition$,
              $location,
              $localStorage,
              $translate,
              instance,
              enums,
              Restangular) {
    "ngInject";

    const flags = {
      token: $localStorage.accessToken || null,
      translations: JSON.stringify($translate.getTranslationTable()),
      instance,
      enums: enums,
      startTime: moment().startOf("month").format(),
      timeNow: moment().format(),
      urlParams: $transition$.params(),
      timeZone: moment.tz.guess(),
      id: $transition$.params().id
    };

    const app = createElmApp($rootScope, $state, Elm.Pages.Instance.IdentifiedFlow.Page, "elm-identifiedflow",
      flags, Restangular, $localStorage);

    subscribe(app, "setSearchParams", (searchParams) => {
      $scope.$apply(() => $location.search(createSearchObject(searchParams)));
    });

    const unregisterFn = $rootScope.$on("locationChangeSuccess", (_, search) => {
      if (!isEmpty(search)) {
        app.ports.updateTimelineUrlParams.send(search);
      }
    });
    $scope.$on("$destroy", unregisterFn);


    function createSearchObject({ selectedTab, pageNumber, applications, operations, integrationSegments }) {
      return {
        selectedTab,
        applications: applications ? applications.join(",") : null,
        operations: operations ? operations.join(",") : null,
        integrationSegments: integrationSegments ? integrationSegments.join(",") : null,
        pageNumber
      };
    }

    subscribe(app, "showApplicationFlowGraph", ({ width, height, transactions }) => {
      if (!isEmpty(transactions)) {
        const parent = document.getElementById("application-graph");
        if (parent) {
          createGraph(parent, transactions, undefined, width, height);
        } else {
          console.error("No element found with id: application-graph");
        }
      }
    });
  }
}
