const registerFi = {
  "register-title": "Luo Uudet Käyttäjätiedot",
  "register-name": "Koko Nimi",
  "register-email-again": "Anna e-mail uudestaan.",
  "register-password": "Salasana",
  "register-password-error": "Salasana on liian lyhyt.",
  "register-register": "Luo",
  "register-email-invalid": "Virheellinen sähköpostiosoite",
  "register-registration-failed-invalid-email":
    "Käyttäjätietojen luonti epäonnistui. Sähköpostiosoite on virheellinen.",
  "register-registration-failed-email-failure":
    "Käyttäjätietojen luonti epäonnistui. Ole hyvä ja tarkista ettet ole jo luonut niitä.",
  "register-finished-title": "Kiitos käyttäjätietojen luonnista",
  "register-finished-text":
    "Sinulle on lähetty sähköposti jossa on linkki, jolla voit saattaa käyttäjätietojen luonnin loppuun.",
  "register-email-mismatch": "Sähköpostit eivät ole samat.",
  "register-name-missing": "Nimesi puuttuu.",
  "register-password-too-short": "Salasana on liian lyhyt.",
  "register-to-create-account": "Need an account for your organization? Create it here.",
  "password-mismatch": "The passwords must be the same."
};

export { registerFi };
