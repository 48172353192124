import template from "./confirm.html";

export function ConfirmDirective() {
  "ngInject";

  return {
    restrict: "E",
    scope: {
      buttonText: "=",
      confirmText: "=",
      cb: "=",
      itemId: "="
    },
    link: (scope) => {
      scope.isConfirmHidden = true;

      scope.toggleConfirm = () => {
        scope.isConfirmHidden = !scope.isConfirmHidden;
        scope.$emit("confirmStatusChange", scope.isConfirmHidden, scope.itemId);
      };

      scope.removeItem = () => scope.cb(scope.itemId);

      scope.$on("resetConfirm", () => (scope.isConfirmHidden = true));
    },
    template
  };
}
