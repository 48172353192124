import "./overview.scss";
import { chain, reduce } from "lodash";
import { EnumUtils } from "../../../utils/EnumUtils";
import moment from "moment";

const FIVE_MINS_AS_MILLIS = moment.duration(5, "minutes").asMilliseconds();

export class OverviewController {
  constructor(
    $scope,
    $rootScope,
    $state,
    $filter,
    $translate,
    instance,
    operationStats,
    applicationStats,
    instanceData,
    enums,
    InstanceService,
    TransactionSummaryService,
    rx
  ) {
    "ngInject";

    this.scope = $scope;
    this.rootScope = $rootScope;
    this.state = $state;
    this.filter = $filter;
    this.translate = $translate;
    this.instance = instance;
    this.operationStats = operationStats;
    this.applicationStats = applicationStats;
    this.instanceData = instanceData;
    this.instanceService = InstanceService;
    this.transactionSummaryService = TransactionSummaryService;
    this.rx = rx;
    this.enums = enums;
    this.ratios = this.calculateRatios();
    this.problematicOperations = this.getProblematicOperations();
    this.problematicApplications = this.getProblematicApplications();

    this.scope.getApplicationsDisplayName = this.getApplicationsDisplayName.bind(this);
    this.scope.getOperationsDisplayName = this.getOperationsDisplayName.bind(this);

    const interval = moment.duration(1, "minute").asMilliseconds();
    const operationTimer = rx.Observable.timer(interval, interval)
      .flatMapLatest(this.getOperationStats.bind(this))
      .subscribe((result) => {
        this.operationStats = result;
        this.problematicOperations = this.getProblematicOperations();
        this.scope.$apply();
      });

    const applicationTimer = rx.Observable.timer(interval, interval)
      .flatMapLatest(this.getApplicationStats.bind(this))
      .subscribe((result) => {
        this.applicationStats = result;
        this.problematicApplications = this.getProblematicApplications();
        this.scope.$apply();
      });

    const instanceTimer = rx.Observable.timer(interval, interval)
      .flatMapLatest(this.getInstanceData.bind(this))
      .subscribe((result) => {
        this.instanceData = result;
        this.ratios = this.calculateRatios();
        this.scope.$apply();
      });

    $scope.$on("$destroy", () => {
      operationTimer.dispose();
      applicationTimer.dispose();
      instanceTimer.dispose();
    });
  }

  getStartTime() {
    return moment(
      this.roundTo5Mins(
        moment()
          .subtract(6, "hours")
          .valueOf()
      )
    );
  }

  getEndTime() {
    return moment();
  }

  getFormattedStartTime() {
    return this.getStartTime().format("HH:mm");
  }

  getFormattedEndTime() {
    return this.getEndTime().format("HH:mm");
  }

  getOperationStats() {
    return this.rx.Observable.fromPromise(
      this.transactionSummaryService.getOperationStats(this.instance._id, this.getStartTime(), this.getEndTime())
    );
  }

  getApplicationStats() {
    return this.rx.Observable.fromPromise(
      this.transactionSummaryService.getApplicationStats(this.instance._id, this.getStartTime(), this.getEndTime())
    );
  }

  getInstanceData() {
    return this.rx.Observable.fromPromise(
      this.transactionSummaryService.getTransactionVolumeForDashboard(
        this.instance._id,
        this.getStartTime(),
        this.getEndTime()
      )
    );
  }

  roundTo5Mins(millis) {
    return Math.floor(millis / FIVE_MINS_AS_MILLIS) * FIVE_MINS_AS_MILLIS;
  }

  calculateRatios() {
    const successVolume = this.getVolume(this.instanceData, "success");
    const failureVolume = this.getVolume(this.instanceData, "failure");
    const unknownVolume = this.getVolume(this.instanceData, "unknown");
    const totalVolume = successVolume + failureVolume + unknownVolume;

    const failureRatio = this.calculateRatio(failureVolume, totalVolume);
    const unknownRatio = this.calculateRatio(unknownVolume, totalVolume);
    const successRatio = successVolume > 0 ? 100 - failureRatio - unknownRatio : 0; // Make sure we always have 100%

    return {
      successVolume,
      failureVolume,
      unknownVolume,
      failureRatio,
      successRatio,
      unknownRatio,
      totalVolume
    };
  }

  getVolume(stats, value) {
    return reduce(stats, (sum, oper) => sum + oper[value], 0);
  }

  calculateRatio(volume, totalVolume) {
    if (totalVolume > 0) {
      return Math.round(volume / totalVolume * 100);
    }
    return 0;
  }

  getProblematicApplications() {
    const apps = chain(this.applicationStats)
      .flatMap((app) => [
        {
          _id: app._id.from,
          failure: app.failure
        },
        {
          _id: app._id.to,
          failure: app.failure
        }
      ])
      .reduce((ret, app) => {
        const prev = ret.find(({ _id }) => _id === app._id);
        if (prev) {
          prev.failure += app.failure;
        } else {
          ret.push(app);
        }
        return ret;
      }, [])
      .value();

    return chain(apps)
      .filter((obj) => obj.failure > 0)
      .sortBy("failure")
      .reverse()
      .take(5)
      .value();
  }

  getProblematicOperations() {
    return chain(this.operationStats)
      .filter((obj) => obj.failure > 0)
      .sortBy("failure")
      .reverse()
      .take(5)
      .value();
  }

  getApplicationsDisplayName(id) {
    return EnumUtils.getApplicationName(id, this.enums);
  }

  getOperationsDisplayName(id) {
    return EnumUtils.getOperationName(id, this.enums);
  }

  getVolumeText() {
    return this.translate.instant("overview-volume-percentage", {
      value: this.filter("numeraljs")(this.ratios.totalVolume, "0[.]0a")
    });
  }

  getSuccessText() {
    return this.translate.instant("overview-success-percentage", {
      value: this.filter("numeraljs")(this.ratios.successRatio, "0[.]0a")
    });
  }

  getFailureText() {
    return this.translate.instant("overview-failure-percentage", {
      value: this.filter("numeraljs")(this.ratios.failureRatio, "0[.]0a")
    });
  }

  getUnknownText() {
    return this.translate.instant("overview-unknown-percentage", {
      value: this.filter("numeraljs")(this.ratios.unknownRatio, "0[.]0a")
    });
  }

  getTimeText() {
    return this.translate.instant("overview-time-text", {
      startTime: this.getFormattedStartTime(),
      endTime: this.getFormattedEndTime()
    });
  }
}
