import "./problematicEnumTable.scss";
import template from "./problematicEnumTable.html";

export function ProblematicEnumTableDirective($translate, $filter) {
  "ngInject";

  return {
    restrict: "E",
    scope: {
      enums: "<",
      ratios: "<",
      title: "@",
      getDisplayName: "&",
      enumType: "@"
    },
    link: (scope) => {
      scope.selectedFilter = "relativeFailures";

      scope.isSelected = (filter) => filter === scope.selectedFilter;

      scope.toggle = (filter) => (scope.selectedFilter = filter);

      scope.calculateFailureRatio = (failures) => {
        const totalFailures = scope.ratios.failureVolume;
        return totalFailures > 0 ? Math.round(failures / totalFailures * 100) : 0;
      };

      scope.calculateFailureRate = (failures) => {
        const totalVolume = scope.ratios.totalVolume;
        return totalVolume > 0 ? (failures / totalVolume * 100).toFixed(2) : 0;
      };

      scope.getRelativeFailuresText = (failures) =>
        $translate.instant("problematic-relative-failures-text", { ratio: scope.calculateFailureRatio(failures) });

      scope.getFailureRateText = (failures) =>
        $translate.instant("problematic-failure-rate-text", { rate: scope.calculateFailureRate(failures) });

      scope.getFailureVolumeText = (failures) =>
        $translate.instant("problematic-failure-volume-text", { volume: $filter("numeraljs")(failures, "0[.]0a") });
    },
    template
  };
}
