import { includes } from "lodash";

export class ErrorInterceptorService {
  constructor($rootScope, $state, $log) {
    "ngInject";

    this.rootScope = $rootScope;
    this.state = $state;
    this.log = $log;
  }

  handleResponse(response) {
    // Horrible hack to figure out if the request was cancelled by the user
    if (response.config.timeout && (response.config.timeout.$$state.value === "cancelled" ||
      response.config.timeout.$$state.value === "timeout")) {
      return true;
    }

    this.log.debug("ErrorInterceptorService.handleResponse", response);

    const error500 = /5\d\d/; // checks 500->599 error codes
    const status = response.status;

    if (status <= 0 || error500.test(status)) {
      this.rootScope.flashErrors.push("connection-lost-error");
    } else if (status === 403) {
      if (isNotIgnoredByMessageType(response.data.message)) {
        this.rootScope.flashErrors.push("no-access-error");
        this.state.go("forbidden");
      } // else error is handled by caller
    } else if (status === 400) {
      // this.rootScope.flashErrors.push("bad-request-error");
    } else if (status === 401) {
      this.rootScope.flashErrors.push("unauthorized-error");
      this.rootScope.$broadcast("unauthorized");
      // controller (instance) does not seem to destroy itself when state changes to login here.
      // Broadcast message to ensure clear intervals.
      this.state.go("login");
    }

    return true;
  }
}

const isNotIgnoredByMessageType = message => {
  const ignorableMessageTypes = ["invalid_confirm_token", "already_confirmed"];
  return !includes(ignorableMessageTypes, message);
};
