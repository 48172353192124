export class LogoutController {
  constructor ($rootScope, $state, UserService) {
    "ngInject";

    this.rootScope = $rootScope;
    this.state = $state;
    this.userService = UserService;
  }

  logout() {
    this.userService.invalidate().then(() => {
      this.state.go("login");
    }).catch(() => {
      this.rootScope.flashErrors.push("logout-failed");
    });
  }
}
