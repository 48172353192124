import { BaseService } from "./base.service";

export class UserService extends BaseService {
  constructor(Restangular, $localStorage, $rootScope) {
    "ngInject";
    super();

    this.restangular = Restangular;
    this.user = Restangular.service("user");
    this.localStorage = $localStorage;
    this.rootScope = $rootScope;

    window.addEventListener("storage", () => {
      if (!this.localStorage.accessToken) {
        delete this.rootScope.user;
      }
    }, false);
  }

  authenticate(credentials) {
    this.restangular.setDefaultHeaders({ "Authorization": null });

    this.localStorage.accessToken = '';

    return new Promise((resolve, reject) => {
      this.login().customPOST(credentials).then(response => {
        this.restangular.setDefaultHeaders({ "Authorization": "JWT " + response.access });
        this.localStorage.accessToken = response.access;
        console.error("Logged in", response.access);
        resolve();
      }).catch(err => {
        reject(err);
      });
    });
  }

  getUser() {
    return new Promise((resolve, reject) => {
      this.user.one().get().then(user => {
        const plainUser = user.plain();
        this.rootScope.user = plainUser;
        resolve(plainUser);
      }).catch(err => {
        reject(err);
      });
    });
  }

  isAuthenticated() {
    return this.rootScope.user;
  }

  invalidate() {
    return new Promise((resolve, reject) => {
      if (!this.localStorage.accessToken) {
        resolve();
      } else {
        this.logout().get().then(() => {
          resolve();
        }).catch(err => {
          reject(err);
        });

        this.restangular.setDefaultHeaders({ "Authorization": null });
        this.localStorage.accessToken = null;
        delete this.rootScope.user;
      }
    });
  }

  login() {
    return this.user.one("login");
  }

  logout() {
    return this.user.one("logout");
  }

  create(newUser) {
    return this.user.one().customPOST(newUser);
  }

  createWithInvitation(newUser, invitationId) {
    return this.user.one("invitation").one(invitationId).customPOST(newUser);
  }

  confirm(token) {
    return this.user.one("confirm").customPOST({ token });
  }

  recoverPassword(email) {
    return this.user.one("passwordreset").customPOST({ email });
  }

  resetPassword(password, token) {
    return this.user.one("passwordreset").one(token).customPOST({ password });
  }

  ratePassword(password) {
    return this.plainData(this.user.one("ratepassword").customPOST({ password }));
  }
}
