import { Elm } from "../../../../../aino.elmproj";
import { createElmApp, subscribe } from "../../../utils/ElmUtils";
import moment from "moment-timezone";
import toMoment from "../../../utils/MomentUtils";

export class ElmOperationsController {
  constructor($scope, $rootScope, $state, $transition$, $location, $localStorage, $translate, instance, enums,
              Restangular) {
    "ngInject";

    const flags = {
      token: $localStorage.accessToken || null,
      translations: JSON.stringify($translate.getTranslationTable()),
      instance,
      enums,
      startTime: $transition$.params().startTime
        ? toMoment($transition$.params().startTime).format()
        : moment()
          .startOf("month")
          .format(),
      timeNow: moment().format(),
      urlParams: $transition$.params(),
      timeZone: moment.tz.guess()
    };

    const app = createElmApp($rootScope, $state, Elm.Pages.Instance.Operations.Page, "elm-operations",
      flags, Restangular, $localStorage);

    subscribe(app, "setSearchParams", (searchParams) => {
      $scope.$apply(() => $location.search(createSearchObject(searchParams)));
    });

    function createSearchObject({ startTime, operations, resolution, status }) {
      return {
        startTime,
        operations: operations ? operations.join(",") : null,
        resolution,
        status: status ? status.join(",") : null,
      };
    }
  }
}
