import "./enum-filter.scss";
import template from "./enum-filter.html";
import { includes, remove } from "lodash";

export function EnumFilterDirective() {
  "ngInject";

  return {
    restrict: "E",
    scope: {
      enums: "=",
      enumNames: "=",
      enumType: "@",
      toggleSelection: "&",
      deselectAll: "&",
      selectAll: "&",
      isSelected: "&"
    },
    link: (scope) => {
      scope.isSelected = (name) => {
        return includes(scope.enumNames, name);
      };

      scope.toggleSelection = (name) => {
        if (includes(scope.enumNames, name)) {
          remove(scope.enumNames, (obj) => obj === name);
        } else {
          scope.enumNames.push(name);
        }
        delete scope.sortItem;
      };

      scope.selectAll = () => {
        scope.enumNames.splice(0, scope.enumNames.length);
        scope.enums.forEach((obj) => scope.enumNames.push(obj.name));
      };

      scope.deselectAll = () => {
        scope.enumNames.splice(0, scope.enumNames.length);
      };

      scope.toggleShowSelected = () => {
        scope.showSelected = !scope.showSelected;
      };

      scope.filterSelected = (item) => {
        if (scope.showSelected) {
          return scope.isSelected(item.name);
        }
        return true;
      };
    },
    template
  };
}
