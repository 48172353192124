import "./users.scss";
import { reduce, find, chain, remove } from "lodash";

export class UsersController {
  constructor($scope, $rootScope, instance, invitedUsers, InstanceService) {
    "ngInject";

    this.scope = $scope;
    this.rootScope = $rootScope;
    this.instance = instance;
    this.invitedUsers = invitedUsers;
    this.users = this.createUsersList(instance, invitedUsers);
    this.instanceService = InstanceService;
    this.emailInput = '';
    this.invitingUser = false;

    this.confirmHidden = reduce(
      this.users,
      (map, user) => {
        map[user._id] = true;
        return map;
      },
      {}
    );

    $scope.removeUser = this.removeUser.bind(this);
    $scope.$on("confirmStatusChange", (_, hidden, userId) => (this.confirmHidden[userId] = hidden));
  }

  getHighlightClass(hover, userId) {
    return {
      users__highlight: hover && this.isConfirmHidden(userId),
      users__highlight_remove: !this.isConfirmHidden(userId)
    };
  }

  isConfirmHidden(userId) {
    return this.confirmHidden[userId];
  }

  parseInput(input) {
    return input
      .split(/[\s*,*\s*;*\s*]/)
      .filter((s) => s !== '')
      .map((s) => s.trim());
  }

  inviteUser() {
    this.invitingUser = true;
    this.instanceService
      .inviteUser(this.instance._id, this.parseInput(this.emailInput))
      .then((users) => {
        this.invitingUser = false;
        this.rootScope.flashMessages.push("users-invitation-success");
        this.instance.userGroupInstance.users = this.instance.userGroupInstance.users.concat(users);
        this.users = this.createUsersList(this.instance, this.invitedUsers);
        this.emailInput = '';
        this.scope.$apply();
      })
      .catch(() => {
        this.invitingUser = false;
        this.rootScope.flashErrors.push("users-invitation-failed");
        this.scope.$apply();
      });
  }

  reinviteUser(user) {
    this.invitingUser = true;
    this.instanceService
      .reinviteUser(this.instance._id, user._id, user.email)
      .then((updatedUser) => {
        this.invitingUser = false;
        this.rootScope.flashMessages.push("users-invitation-success");
        user.status = updatedUser.status;
        this.scope.$apply();
      })
      .catch(() => {
        this.invitingUser = false;
        this.rootScope.flashErrors.push("users-invitation-failed");
        this.scope.$apply();
      });
  }

  removeUser(user) {
    this.instanceService
      .removeUser(this.instance._id, user._id)
      .then(() => {
        this.instance.userGroupInstance.users = this.instance.userGroupInstance.users.filter(u => u._id !== user._id);
        this.users = this.createUsersList(this.instance, this.invitedUsers);
      })
      .catch((e) => {
        this.rootScope.flashErrors.push("users-remove-user-failed");
        this.scope.$apply();
      });
  }

  createUsersList(instance, invitedUsers) {
    const admins = instance.customership.admins;
    const users = instance.users;
    const userGroupUsers = instance.userGroupInstance.users;

    const acceptedInvitations = remove(invitedUsers, (i) => i.status === "accepted");

    return chain(users)
      .union(admins)
      .union(userGroupUsers)
      .uniqWith((arrVal, othVal) => arrVal.email === othVal.email)
      .sort(this.sortByName.bind(this))
      .sort(this.sortByRole.bind(this))
      .sort(this.sortByStatus.bind(this))
      .map((user) => this.updateUser(user, acceptedInvitations))
      .value();
  }

  updateUser(user, acceptedInvitations) {
    const invitation = acceptedInvitations.find((i) => i.email === user.email);
    if (invitation) {
      user.inviter = {
        email: invitation.inviter ? invitation.inviter.email : "",
        name: invitation.inviter ? invitation.inviter.name : ""
      };
      user.invitationTime = invitation.createdAt;
    }
    return user;
  }

  containsInstanceUserEmail(emails) {
    return this.users.find((u) => emails.find((e) => e === u.email) !== undefined) !== undefined;
  }

  isAdmin(user) {
    const admins = this.instance.customership.admins.concat(this.instance.users);
    return find(admins, (admin) => admin.email === user.email) !== undefined;
  }

  containsEmail(emails, email) {
    return emails.find((e) => e === email) !== undefined;
  }

  getUserString(user) {
    return user ? user.name + " (" + user.email + ")" : '';
  }

  sortByStatus(a, b) {
    if (a.status === "pending") {
      return -1;
    } else if (b.status === "pending") {
      return 1;
    } else if (a.status === "rejected") {
      return -1;
    } else if (b.status === "rejected") {
      return 1;
    }
    return 0;
  }

  sortByRole(a, b) {
    const isAdminA = this.isAdmin(a);
    const isAdminB = this.isAdmin(b);
    if (isAdminA && !isAdminB) {
      return 1;
    } else if (!isAdminA && isAdminB) {
      return -1;
    }
    // Both are admins or both are users
    return 0;
  }

  sortByName(a, b) {
    if (a.name && b.name) {
      return a.name.localeCompare(b.name);
    } else if (a.name && !b.name) {
      return -1;
    } else if (!a.name && b.name) {
      return 1;
    }
    // Neither has a name
    return 0;
  }
}
