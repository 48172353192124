export function config(
  $logProvider,
  $locationProvider,
  $translateSanitizationProvider,
  $translateProvider,
  toastrConfig
) {
  "ngInject";

  // Enable log
  $logProvider.debugEnabled(enableDebugLogging);

  // Remove ! from the URL
  $locationProvider.hashPrefix('');

  $translateSanitizationProvider.addStrategy("sce", "sceStrategy");
  $translateProvider.useSanitizeValueStrategy("sce");

  // Set options third-party lib
  toastrConfig.allowHtml = true;
  toastrConfig.timeOut = 3000;
  toastrConfig.positionClass = "toast-top-right";
  toastrConfig.preventDuplicates = true;
  toastrConfig.progressBar = true;
}
