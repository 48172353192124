import { BaseService } from "./base.service";

export class CustomershipService extends BaseService {
  constructor(Restangular, $log) {
    "ngInject";
    super();
    this.$log = $log;
    this.customership = Restangular.service("customership");
  }

  getList() {
    return super.plainData(this.customership.getList());
  }

  acceptTos() {
    return this.customership.one("tos").customPUT();
  }
}
