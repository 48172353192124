import { BaseService } from "./base.service";

export class InvitationService extends BaseService {
  constructor(Restangular) {
    "ngInject";

    super();

    this.invitation = Restangular.service("invitation");
  }

  getInvitationsForUser() {
    return super.plainData(this.invitation.one().get());
  }

  getInvitation(invitationId) {
    return super.plainData(this.invitation.one(invitationId).get());
  }
}
