import { find, map } from "lodash";

export const EnumUtils = {
  getEnums: function (type, enums) {
    return enums.filter((e) => e.type === type);
  },

  getName: function (type, enumId, enums) {
    const e = enums.find((e) => e._id.toString() === enumId.toString() && e.type === type);
    return e ? e.name : "Pending...";
  },

  getApplicationName: function (enumId, enums) {
    return this.getName("application", enumId, enums);
  },

  getOperationName: function (enumId, enums) {
    return this.getName("operation", enumId, enums);
  },

  getIntegrationSegmentName: function (enumId, enums) {
    return this.getName("integrationSegment", enumId, enums);
  },

  getMetadataName: function (enumId, enums) {
    return this.getName("metadataName", enumId, enums);
  },

  getIdTypeName: function (enumId, enums) {
    return this.getName("idType", enumId, enums);
  },

  getPayloadTypeName: function (enumId, enums) {
    return this.getName("payloadType", enumId, enums);
  },

  getApplications: function (enums) {
    return this.getEnums("application", enums);
  },

  getOperations: function (enums) {
    return this.getEnums("operation", enums);
  },

  getUserGroupOperations: function (instance, enums, userGroupInstance) {
    const operations = this.getOperations(enums);
    if (userGroupInstance.showAllOperations || instance.sessionUserIsCustomershipAdmin || instance.sessionUserIsInstanceAdmin) {
      return operations;
    }

    return operations.filter(oper => {
      return userGroupInstance.operations.includes(oper._id);
    });
  },

  getUserGroupApplications: function (instance, enums, userGroupInstance) {
    const applications = this.getApplications(enums);
    if (userGroupInstance.showAllOperations || instance.sessionUserIsCustomershipAdmin || instance.sessionUserIsInstanceAdmin) {
      return applications;
    }

    return applications.filter(app => {
      return userGroupInstance.applications.includes(app._id);
    });
  },

  getApplicationNames: function (enums) {
    return this.getApplications(enums).map(e => e.name);
  },

  getOperationNames: function (enums) {
    return this.getOperations(enums).map(e => e.name);
  },

  getEnumSearchParams: function (selected, full) {
    const params = this.getParameters(selected, full);
    return params && params.length > 0 ? params.join(",") : undefined;
  },

  getParameters: function (from, orig) {
    if (!this.areSame(from, orig)) {
      return map(from, (name) => this.findId(orig, name));
    }
    return [];
  },

  areSame: function (from, orig) {
    return !from || from.length === orig.length || from.length === 0;
  },

  findId: function (from, name) {
    return find(from, (obj) => obj.name === name)._id;
  }
};
