import { isEmpty } from "lodash";
import moment from "moment-timezone";
import { Elm } from "../../../../../aino.elmproj";
import { createElmApp, subscribe } from "../../../utils/ElmUtils";
import { createGraph } from "../../../utils/treeGraph";

export class ElmTransactionController {
  constructor($scope, $rootScope, $state, $transition$, $localStorage, $translate, Restangular, instance,
              enums) {
    "ngInject";

    this.app = createElmApp($rootScope, $state, Elm.Pages.Instance.Transaction.Page, "elm-trx-view", {
      token: $localStorage.accessToken || null,
      translations: JSON.stringify($translate.getTranslationTable()),
      instance,
      enums,
      timeNow: moment().format(),
      timeZone: moment.tz.guess(),
      showBackButton: $rootScope.previousStates.length > 0,
      selectedTransactionId: $transition$.params().transactionId,
      searchString: $transition$.params().searchString || "",
      urlParams: $transition$.params()
    }, Restangular, $localStorage);

    subscribe(this.app, "toPreviousStateWithKludgeParams", () => {
      if ($rootScope.previousStates.length > 0) {
        const previousState = $rootScope.previousStates.pop();
        const previousParams = $rootScope.previousStateParams.pop();
        const params = this.kludgeParams(previousParams, previousState.name);
        $state.go(previousState.name, params);
      }
    });

    subscribe(this.app, "showApplicationFlowGraph", ({ width, height, selectedTransactionId, transactions }) => {
      if (!isEmpty(transactions)) {
        const parent = document.getElementById("application-graph");
        if (parent) {
          createGraph(parent, transactions, selectedTransactionId, width, height);
        } else {
          console.error("No element found with id: application-graph");
        }
      }
    });
  }

  kludgeParams(params, name) {
    if (name === "timeline") {
      return {
        ...params,
        showTransactionList: 1
      };
    }
    return {
      ...params,
      showFlowList: 1
    };
  }
}
