import * as d3 from "d3";

const minZoom = 1;
const maxZoom = 2;

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/tree
export function Tree(data, { // data is either tabular (array of objects) or hierarchy (nested objects)
  path, // as an alternative to id and parentId, returns an array identifier, imputing internal nodes
  id = Array.isArray(data) ? d => d.id : null, // if tabular data, given a d in data, returns a unique identifier (string)
  parentId = Array.isArray(data) ? d => d.parentId : null, // if tabular data, given a node d, returns its parent’s identifier
  children, // if hierarchical data, given a d in data, returns its children
  tree = d3.tree, // layout algorithm (typically d3.tree or d3.cluster)
  sort, // how to sort nodes prior to layout (e.g., (a, b) => d3.descending(a.height, b.height))
  label, // given a node d, returns the display name
  title, // given a node d, returns its hover text
  link, // given a node d, its link (if any)
  linkTarget = "_blank", // the target attribute for links (if any)
  width = 640, // outer width, in pixels
  height, // outer height, in pixels
  r = 3, // radius of nodes
  padding = 1, // horizontal padding for first and last column
  fill = "#999", // fill for nodes
  fillOpacity, // fill opacity for nodes
  stroke = "#555", // stroke for links
  strokeWidth = 1.5, // stroke width for links
  strokeOpacity = 0.4, // stroke opacity for links
  strokeLinejoin, // stroke line join for links
  strokeLinecap, // stroke line cap for links
  halo = "#fff", // color of label halo
  haloWidth = 3, // padding around the labels
} = {}) {

  // If id and parentId options are specified, or the path option, use d3.stratify
  // to convert tabular data to a hierarchy; otherwise we assume that the data is
  // specified as an object {children} with nested objects (a.k.a. the “flare.json”
  // format), and use d3.hierarchy.
  const root = path != null ? d3.stratify().path(path)(data)
    : id != null || parentId != null ? d3.stratify().id(id).parentId(parentId)(data)
      : d3.hierarchy(data, children);

  // Compute labels and titles.
  const descendants = root.descendants();
  const L = label == null ? null : descendants.map(d => label(d.data, d));

  // Sort the nodes.
  if (sort != null) root.sort(sort);

  // Compute the layout.
  const dx = 10;
  const dy = width / (root.height + padding);
  tree().nodeSize([dx, dy])(root);

  // Center the tree.
  let x0 = Infinity;
  let x1 = -x0;
  root.each(d => {
    if (d.x > x1) x1 = d.x;
    if (d.x < x0) x0 = d.x;
  });

  // Compute the default height.
  if (height === undefined) height = dy / 2;

  const svg = d3.create("svg")
    .attr("viewBox", [-dy / 2, 0, width, height])
    .attr("style", "max-width: 100%; max-height: 100%")
    .attr("font-family", "sans-serif")
    .attr("font-size", 10)
    .attr("align", "center");

  svg.append("g")
    .attr("fill", "none")
    .attr("stroke-opacity", strokeOpacity)
    .attr("stroke-linecap", strokeLinecap)
    .attr("stroke-linejoin", strokeLinejoin)
    .selectAll("path")
    .data(root.links())
    .join("path")
    .attr("stroke", d =>
      d.source.data.selected && d.target.data.selected ? "#1b8383" : stroke
    )
    .attr("stroke-width", d => d.source.data.selected && d.target.data.selected ? strokeWidth * 2 : strokeWidth)
    .attr("d", d3.linkHorizontal()
      .x(d => d.y)
      .y(d => d.x + height / 2 - 5));

  const node = svg.append("g")
    .selectAll("g")
    .data(root.descendants())
    .join("g")
    /*.attr("xlink:href", link == null ? null : d => link(d.data, d))
    .attr("target", link == null ? null : linkTarget)*/
    .attr("transform", d => `translate(${d.y},${d.x + height / 2 - 5})`);

  node.append("circle")
    .attr("fill", d => d.data.selected ? "#1b8383" : stroke)
    .attr("r", d => d.data.selected ? r * 2 : r);

  if (title != null) node.append("title")
    .text(d => title(d.data, d));

  const textTransformX = d => {
    if (d.data.selected) {
      return d.children ? -8 : 8;
    }
    return d.children ? -6 : 6;
  }

  if (L) node.append("text")
    .attr("fill", d => d.data.selected ? "#1b8383" : "#000")
    .attr("font-weight", d => d.data.selected ? "bold" : "normal")
    .attr("dy", "0.32em")
    .attr("x", textTransformX)
    .attr("text-anchor", d => d.children ? "end" : "start")
    .text((d, i) => L[i])
    .call(text => text.clone(true))
    .attr("stroke", halo)
    .attr("stroke-width", haloWidth);

  const zoom = d3.zoom().scaleExtent([minZoom, maxZoom]);

  zoom.on("zoom", (event) => {
    svg.attr("transform", event.transform);
  });

  svg.call(zoom);

  return svg;
}
