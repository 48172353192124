import moment from "moment";
import { InstanceService } from "../../components/service/instance.service";

export function routerInstanceConfig($stateProvider) {
  "ngInject";

  $stateProvider
    .state("instance", {
      url: "/instance/:instanceId",
      redirectTo: "instance.overview",
      views: {
        "header@instance": {
          template: require("../../features/instance/header.html").default
        },
        "main-view": {
          template: require("../../features/instance/instance.html").default,
          controller: "InstanceController",
          controllerAs: "instanceCtrl"
        }
      },
      resolve: {
        instance: (InstanceService, $transition$) =>
          InstanceService.getInstance($transition$.params().instanceId)
            .then((instance) => instance)
            .catch((err) => console.error(err)),

        enums: (InstanceService, $transition$) =>
          InstanceService.getEnums($transition$.params().instanceId)
            .catch((err) => console.error(err)),

        unresolvedCountObj: (AlertService, $transition$) =>
          AlertService.getUnresolvedCount($transition$.params().instanceId)
            .then((response) => ({ unresolvedCount: response.count }))
            .catch((err) => console.error(err)),

        errorCount: (InstanceService, $transition$) =>
          InstanceService.getErrorCount($transition$.params().instanceId)
            .then((response) => ({ errorCount: response.count }))
            .catch((err) => console.error(err))
      },
      data: { authenticated: true }
    })
    .state("instance.errors", {
      url: "/error",
      views: {
        content: {
          template: require("../../features/instance/errors/template.html").default,
          controller: "ElmErrorsController"
        }
      },
      resolve: {
        errors: (InstanceService, $transition$) => InstanceService.getErrors($transition$.params().instanceId),
        warnings: (InstanceService, $transition$) => InstanceService.getWarnings($transition$.params().instanceId),
        settings: (SettingsService, $transition$) => SettingsService.getErrorSettings($transition$.params().instanceId)
      },
      data: { authenticated: true }
    })
    .state("instance.error", {
      url: "/error/:batchId/{index}",
      views: {
        content: {
          template: require("../../features/instance/error/template.html").default,
          controller: "ElmErrorController"
        }
      },
      resolve: {
        errors: (InstanceService, $transition$) => InstanceService.getErrorsWithBatchId($transition$.params().instanceId, $transition$.params().batchId)
      },
      data: { authenticated: true }
    })
    .state("instance.warning", {
      url: "/warning/:batchId/{index}",
      views: {
        content: {
          template: require("../../features/instance/warning/template.html").default,
          controller: "ElmWarningController"
        }
      },
      resolve: {
        warnings: (InstanceService, $transition$) => InstanceService.getWarningsWithBatchId($transition$.params().instanceId, $transition$.params().batchId)
      },
      data: { authenticated: true }
    })
    .state("instance.applicationStats", {
      url: "/applications?startTime?resolution?operations?applications?status?selectedTab",
      views: {
        content: {
          template: require("../../features/instance/applications/template.html").default,
          controller: "ElmApplicationsController"
        }
      },
      resolve: {
        operationMaps: (InstanceService, $transition$) => InstanceService.getOperationMaps($transition$.params().instanceId)
      },
      reloadOnSearch: false,
      dynamic: true,
      params: {
        resolution: {
          value: "day"
        },
        startTime: {
          value: moment().startOf("month").valueOf(),
          type: "int"
        },
        operations: {
          value: null
        },
        applications: {
          value: null
        },
        status: {
          value: null
        },
        selectedTab: {
          value: null
        }
      },
      data: { authenticated: true }
    })
    .state("instance.operationStats", {
      url: "/operations?startTime?resolution?status?operations",
      views: {
        content: {
          template: require("../../features/instance/operations/template.html").default,
          controller: "ElmOperationsController"
        }
      },
      reloadOnSearch: false,
      dynamic: true,
      params: {
        resolution: {
          value: "day"
        },
        startTime: {
          value: moment().startOf("month").valueOf(),
          type: "int"
        },
        operations: {
          value: null
        },
        status: {
          value: null
        }
      },
      data: { authenticated: true }
    })
    .state("instance.overview", {
      url: "/overview",
      views: {
        content: {
          template: require("../../features/instance/overview/overview.html").default,
          controller: "OverviewController",
          controllerAs: "ctrl"
        }
      },
      resolve: {
        operationStats: (TransactionSummaryService, $transition$) => {
          return TransactionSummaryService.getOperationStats(
            $transition$.params().instanceId,
            moment().subtract(6, "hours"),
            moment()
          );
        },

        applicationStats: (TransactionSummaryService, $transition$) => {
          return TransactionSummaryService.getApplicationStats(
            $transition$.params().instanceId,
            moment().subtract(6, "hours"),
            moment()
          );
        },

        instanceData: ($transition$, TransactionSummaryService) =>
          TransactionSummaryService.getTransactionVolumeForDashboard(
            $transition$.params().instanceId,
            moment().subtract(6, "hour"),
            moment()
          )
      },
      data: { authenticated: true }
    });
}
