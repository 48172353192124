import "./alerts.scss";
import { Elm } from "../../../../../aino.elmproj";
import { createElmApp, subscribe } from "../../../utils/ElmUtils";
import moment from "moment-timezone";
import { isEmpty, isNil } from "lodash";
import { createGraph } from "../../../utils/treeGraph";

function getParentFlows(namedFlows) {
  const parentFlows = namedFlows.filter(f => isNil(f.parent));
  return parentFlows.map(parentFlow => {
    const subFlows = namedFlows.filter(f => {
      return !isNil(f.parent) && f.parent.toString() === parentFlow._id.toString();
    })
      .map(f => f._id);
    return {
      _id: parentFlow._id,
      name: parentFlow.name,
      subFlows
    };
  });
}

export class ElmAlertsController {
  constructor($rootScope, $scope, $location, $transition$, $translate, $localStorage, $state, instance,
    unresolvedCountObj, enums, namedFlows, Restangular) {
    "ngInject";

    const parentFlows = getParentFlows(namedFlows);
    const subFlows = namedFlows.filter(f => !isNil(f.parent));

    const app = createElmApp($rootScope, $state, Elm.Pages.Instance.Alerts.Page, "elm-alerts", {
      token: $localStorage.accessToken || null,
      translations: JSON.stringify($translate.getTranslationTable()),
      instance,
      enums,
      urlParams: $transition$.params(),
      timeZone: moment.tz.guess(),
      namedFlows,
      identifiedFlows: parentFlows,
      namedSubFlows: subFlows,
      unresolvedCount: unresolvedCountObj.unresolvedCount
    }, Restangular, $localStorage);

    const unregisterFn = $rootScope.$on("locationChangeSuccess", (_, search) => {
      app.ports.updateTimelineUrlParams.send(search);
    });
    $scope.$on('$destroy', unregisterFn);

    subscribe(app, "saveUnresolvedCount", (count) => {
      unresolvedCountObj.unresolvedCount = count;
      $rootScope.$apply();
      app.ports.updateUnresolvedCount.send(unresolvedCountObj.unresolvedCount);
    });

    subscribe(app, "setSearchParams", (searchParams) => {
      $scope.$apply(() => $location.search(createSearchObject(searchParams)));
    });

    subscribe(app, "clearSearchParams", () => {
      $scope.$apply(() => $location.search({}));
    });

    subscribe(app, "showApplicationFlowGraph", ({ width, height, transactions, domId }) => {
      if (!isEmpty(transactions)) {
        const parent = document.getElementById(domId);
        if (parent) {
          createGraph(parent, transactions, undefined, width, height);
        } else {
          console.error(`No element found with id: ${domId}`);
        }
      }
    });
  }
}

function createSearchObject({ pageNumber, showFlows, alertId, configurationId, groupId }) {
  return {
    pageNumber,
    showFlows: showFlows || null,
    alertId: alertId || null,
    configurationId: configurationId || null,
    groupId: groupId || null
  };
}
