import { assign } from "lodash";

import { commonEn } from "./i18n.common.en";
import { commonFi } from "./i18n.common.fi";

import { trxEn } from "./i18n.transaction.en";
import { trxFi } from "./i18n.transaction.fi";

import { alertEn } from "./i18n.alert.en";
import { alertFi } from "./i18n.alert.fi";

import { registerEn } from "./i18n.register.en";
import { registerFi } from "./i18n.register.fi";

import { en } from "./i18n.en";
import { fi } from "./i18n.fi";

import { errorEn } from "./i18n.error.en";
import { errorFi } from "./i18n.error.fi";

import { usersEn } from "./i18n.users.en";
import { usersFi } from "./i18n.users.fi";

import { formEn } from "./i18n.form.en";
import { formFi } from "./i18n.form.fi";

import { accountEn } from "./i18n.account.en";
import { accountFi } from "./i18n.account.fi";

export function i18nConfig($translateProvider) {
  "ngInject";

  $translateProvider
    .translations("en", assign({}, commonEn, trxEn, alertEn, en, errorEn, registerEn, usersEn, formEn, accountEn))
    .translations("fi", assign({}, commonFi, trxFi, alertFi, fi, errorFi, registerFi, usersFi, formFi, accountFi))
    .preferredLanguage("en")
    .directivePriority(666);
}
