const usersEn = {
  "users-invite-user-plus": "+ Invite People",
  "users-invite-user-minus": "- Invite People",
  "users-invite-submit": "Invite",
  "users-invite-current-users": "Instance Users",
  "users-invite-invited-users": "Invited Users",
  "users-invitation-success": "Successfully invited user.",
  "users-invitation-failed": "Failed to add user. Please try again.",
  "users-invitation-get-users-failed": "Failed to get user list. Please refresh page.",
  "users-remove-user-question": "Really remove the user from the instance?",
  "users-remove-user-failed": "Failed to remove the user.",
  "users-cannot-invite-yourself": "You cannot invite yourself.",
  "users-is-already-instance-user": "User is already in the instance.",
  "users-reinvite": "Reinvite",
  "users-remove-invitation-failed": "Failed to remove invitation."
};

export { usersEn };
