const trxEn = {
  "transaction-identifiers": "Identifiers",
  "transaction-details": "Transaction Details",
  "transaction-flow": "Transaction Flow",
  "transaction-metadata": "Metadata",
  "transactions-no-applications-selected": "Please select at least one application.",
  "transaction-error-type": "Type",
  "transaction-error-path": "Path",
  "transaction-error-kind": "Kind",
  "transaction-error-value": "Value",
  "transaction-error-message": "Message",
  "transaction-error-batch": "Batch",
  "transaction-error-errors": "Errors",
  "transaction-error-warnings": "Warnings",
  "transaction-error-error": "Error",
  "transaction-error-warning": "Warning",
  "transactions-timeout": "Request timed out. Please use a smaller timeframe for the query.",
  "transactions-status": "Status",
  "flow-status": "Flow Status",
  "transactions-last-status": " Last Status",
  "transactions-from": "From",
  "transactions-to": "To",
  "transactions-integrationSegment": "Integration Segment",
  "transactions-payloadType": "Payload Type",
  "transactions-date-time": "Date & Time",
  "transaction-operation": "Operation",
  "transaction-message": "Message",
  "transaction-success-kind": "Successfull",
  "transaction-unknown-kind": "with an unknown status",
  "transaction-failure-kind": "Failed",
  "transactions-containing": "containing",
  "transaction": "transaction",
  "transaction-flow-id": "Flow Id",
  "transaction-api-warnings": "API warnings",
  "transaction-flow-failed": "Connection error",
  "failed-to-search-timeline": "Failed to search for IDs."
};

export { trxEn };
