import "./flash-message.scss";
import template from "./flash-message.html";

const errorCancelers = {};
const successCancelers = {};

export function FlashMessageDirective($interval) {
  "ngInject";

  return {
    restrict: "E",
    link: (scope) => {
      scope.errorMessages = {};
      scope.successMessages = {};

      scope.$watch(
        "flashErrors",
        () => {
          const newMessage = scope.flashErrors.pop();
          if (newMessage) {
            const msgStr = newMessage.toString();
            scope.errorMessages[msgStr] = newMessage;
            if (errorCancelers[msgStr]) {
              $interval.cancel(errorCancelers[msgStr]);
            }
            const errorMessageCanceler = $interval(
              () => {
                delete scope.errorMessages[msgStr];
                $interval.cancel(errorMessageCanceler);
              },
              3000,
              1
            );
            errorCancelers[msgStr] = errorMessageCanceler;
          }
        },
        true
      );

      scope.$watch(
        "flashMessages",
        () => {
          const newMessage = scope.flashMessages.pop();
          if (newMessage) {
            const msgStr = newMessage.toString();
            scope.successMessages[msgStr] = newMessage;
            if (successCancelers[msgStr]) {
              $interval.cancel(successCancelers[msgStr]);
            }
            const successMessageCanceler = $interval(
              () => {
                delete scope.successMessages[msgStr];
                $interval.cancel(successMessageCanceler);
              },
              3000,
              1
            );
            successCancelers[msgStr] = successMessageCanceler;
          }
        },
        true
      );

      scope.$on("clearFlashMessages", () => {
        scope.errorMessages = {};
        scope.successMessages = {};
      });
    },
    template
  };
}
