const commonFi = {
  "common-details": "Tiedot",
  from: '',
  to: "->",
  create: "Luo uusi avain",
  duplicate: "Tuplaa",
  edit: "Muokkaa",
  update: "Päivitä",
  cancel: "Peruuta",
  "common-ok": "Ok",
  "common-close": "Sulje",
  "common-search": "Hae",
  "back-to-login": "Already have credentials? Go to login.",
  "common-email": "Sähköposti",
  "common-emails": "Sähköpostit",
  "common-manage": "Hallitse",
  "common-yes": "Kyllä",
  "common-no": "Ei",
  "common-name": "Nimi",
  "common-inviter": "Kutsuja",
  "common-status": "Status",
  "common-invitation-time": "Kutsuaika",
  "common-settings": "Asetukset"
};

export { commonFi };
