import { Elm } from "../../../../../aino.elmproj";
import { createElmApp } from "../../../utils/ElmUtils";
import moment from "moment-timezone";

export class ElmLinkedFlowsController {
  constructor($rootScope, $state, $filter, $translate, $transition$, instance, enums, Restangular, $localStorage) {
    "ngInject";

    createElmApp($rootScope, $state, Elm.Pages.Instance.LinkedFlows.Page, "elm-linked-flows", {
      translations: JSON.stringify($translate.getTranslationTable()),
      instance,
      token: $localStorage.accessToken || null,
      enums,
      timeZone: moment.tz.guess()
    }, Restangular, $localStorage);
  }
}
