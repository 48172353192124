import { Elm } from "../../../../../aino.elmproj";
import { createElmApp } from "../../../utils/ElmUtils";
import { find, omit, pick } from "lodash";

export class ElmErrorController {
  constructor($rootScope, $state, $filter, $translate, $transition$, instance, errors, Restangular, $localStorage) {
    "ngInject";

    const error = findBatch(errors, $transition$.params().batchId);

    createElmApp($rootScope, $state, Elm.Pages.Instance.ErrorPage, "elm-error", {
      translations: JSON.stringify($translate.getTranslationTable()),
      instance,
      errors: getTransactionErrors(error, $transition$.params().index),
      transaction: $filter("json")(getTransactionDetails(error, $transition$.params().index))
    }, Restangular, $localStorage);
  }
}

function findBatch(errors, batchId) {
  return find(errors, (error) => error.batchId === batchId);
}

function getTransactionDetails(error, index) {
  return omit(error.transactions[index], "errors");
}

function getTransactionErrors(error, index) {
  return pick(error.transactions[index], "errors").errors;
}
