import { Elm } from "../../../../../aino.elmproj";
import { createElmApp } from "../../../utils/ElmUtils";
import { isEmpty } from "lodash";

export class ElmIdentifiedFlowsController {
  constructor($scope,
              $rootScope,
              $state,
              $transition$,
              $location,
              $localStorage,
              $translate,
              instance,
              enums,
              Restangular) {
    "ngInject";

    const flags = {
      token: $localStorage.accessToken || null,
      translations: JSON.stringify($translate.getTranslationTable()),
      instance,
      enums: enums,
      urlParams: $transition$.params()
    };

    const app = createElmApp($rootScope, $state, Elm.Pages.Instance.IdentifiedFlows.Page, "elm-identifiedflows",
      flags, Restangular, $localStorage);

    const unregisterFn = $rootScope.$on("locationChangeSuccess", (_, search) => {
      if (!isEmpty(search)) {
        app.ports.updateTimelineUrlParams.send(search);
      }
    });
    $scope.$on("$destroy", unregisterFn);

    app.ports.setSearchParams.subscribe(searchParams => {
      $scope.$apply(() => $location.search(createSearchObject(searchParams)));
    });

    function createSearchObject({
                                  applications, operations, pageNumber, searchFilterOpen
                                }) {
      return {
        searchFilterOpen: searchFilterOpen || null,
        applications: applications ? applications.join(",") : null,
        operations: operations ? operations.join(",") : null,
        pageNumber
      };
    }
  }
}
