const registerEn = {
  "register-title": "Create New Login Credentials",
  "register-name": "Full Name",
  "register-email-again": "Re-enter e-mail",
  "register-password": "Password",
  "register-password-error": "Password is too short.",
  "register-register": "Create",
  "register-email-invalid": "Invalid e-mail address",
  "register-registration-failed-invalid-email":
    "Credential creation failed. E-mail contains invalid characters.",
  "register-registration-failed-email-failure":
    "Credential creation failed. Please check that you have not already created them.",
  "register-finished-title": "Thank you for creating new credentials",
  "register-finished-text":
    "You will receive an e-mail with a confirmation link for finishing the credential creation.",
  "register-email-mismatch": "The e-mails do not match.",
  "register-name-missing": "Your name is missing.",
  "register-password-too-short": "Password is too short.",
  "register-to-create-account": "Need an account for your organization? Create it here.",
  "password-mismatch": "The passwords must be the same."
};

export { registerEn };
