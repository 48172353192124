export function routerInstanceAlertsConfig($stateProvider) {
  "ngInject";

  $stateProvider
    .state("instance.alerts", {
      url: "/alert?pageNumber?showFlows?alertId?configurationId?groupId?newConfiguration?backToPreviousState?newConfigurationSelectedId",
      views: {
        content: {
          template: require("../../features/instance/alerts/template.html").default,
          controller: "ElmAlertsController"
        }
      },
      reloadOnSearch: false,
      data: { authenticated: true },
      resolve: {
        namedFlows: (IdentifiedFlowService, $transition$) => IdentifiedFlowService.getShowInAlertsFlows($transition$.params().instanceId)
      },
      params: {
        pageNumber: {
          value: null,
          type: "int"
        },
        showFlows: {
          value: null
        },
        alertId: {
          value: null
        },
        configurationId: {
          value: null
        },
        groupId: {
          value: null
        },
        newConfiguration: {
          value: null
        },
        backToPreviousState: {
          value: null
        },
        newConfigurationSelectedId: {
          value: null
        }
      }
    });
}
