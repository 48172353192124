import { Elm } from "../../../../../aino.elmproj";
import { createElmApp } from "../../../utils/ElmUtils";
import moment from "moment-timezone";
import toMoment from "../../../utils/MomentUtils";

export class ElmFlowExportController {
  constructor($scope,
              $rootScope,
              $state,
              $transition$,
              $location,
              $localStorage,
              $translate,
              instance,
              enums,
              Restangular,
              namedFlows) {
    "ngInject";

    createElmApp($rootScope, $state, Elm.Pages.Instance.FlowExport.Page, "elm-flow-export", {
      token: $localStorage.accessToken || null,
      translations: JSON.stringify($translate.getTranslationTable()),
      instance,
      enums: enums,
      startTime: $transition$.params().startTime ? toMoment($transition$.params().startTime).format() :
        moment().startOf("hour").format(),
      timeNow: moment().format(),
      urlParams: $transition$.params(),
      timeZone: moment.tz.guess(),
      namedFlows
    }, Restangular, $localStorage);
  }
}
