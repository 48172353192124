const alertFi = {
  "alert-configuration-management-title": "Häly Konfiguraatio",
  "alert-configuration-name-input": "Uuden hälyn nimi",
  "alert-configuration-management-name-input-warning": "Varoitus! Nimi on jo olemassa.",
  "alert-configuration-management-interval-title": "Aikaväli",
  "alert-configuration-creation-error": "Häly Konfiguraation luonti epäonnistui!",
  "alert-configuration-toggle-success": "OK! Muutos tulee voimaan seuraavan häly-tarkastuksen jälkeen.",
  "alert-configuration-toggle-error": "Hälyn togglaus epäonnistui!",
  "alert-configuration-management-name": "Nimi",
  "alert-configuration-management-interval": "Aikaväli",
  "alert-configuration-management-manage": "Käsittele",
  "alert-configuration-management-enable": "Päälle",
  "alert-configuration-management-disable": "Pois päältä",
  "alert-configuration-management-disabled": "(Disabloitu)",
  "alert-configuration-management-identifier": "Identifier found in transactions",
  "alert-configuration-management-no-identifier": "Identifier not found in transactions",
  "alert-configuration-management-users": "Vastaanottajien Sähköpostiosoitteet",
  "alert-configuration-management-enable-users": "Kaikki päälle",
  "alert-configuration-management-disable-users": "Kaikki pois päältä",
  "alert-configuration-management-remove": "Poista",
  "alert-configuration-management-remove-question": "Haluatko poistaa konfiguraation ja siihen liittyvät hälyt?",
  "alert-configuration-users-toggle-error": "Käyttäjien togglaus epäonnistui.",
  "alert-configuration-management-customemail-input": "E-mail",
  "alert-configuration-management-add-customemail": "Lisää",
  "alert-configuration-management-remove-customemail": "Poista",
  "alert-configuration-management-duplicate-customemail-email": "Sähköposti on jo mukana hälyssä.",
  "alert-configuration-removal-error": "Häly konfiguraation poisto epäonnistui",
  "alert-configuration-create-new": "Luo uusi Häly",
  "alert-configuration-management-trigger-title": "Laukaisija",
  "alert-configuration-trigger-gt-label": "Tuloksia enemmän kuin",
  "alert-configuration-trigger-lt-label": "Tuloksia vähemmän kuin",
  "alert-configuration-trigger-gt-input": "Numero ( 0 tai enemmän )",
  "alert-configuration-trigger-lt-input": "Numero ( suurempi, kuin edellinen arvo)",
  "alert-configuration-trigger-invalid": "Tarkista arvot",
  "alert-configuration-management-limit-reached": "Aktiivisen suunnitelman alertti raja on saavutettu!",
  "alert-configuration-select-timezone": "Valitse uusi aikavyöhyke",
  "alert-configuration-current-timezone": "Nykyinen aikavyöhyke:",
  "alert-configuration-change-timezone": "Vaihda",
  "alerts-groups-name": "Nimi",
  "alerts-groups-latest": "Uusin",
  "alerts-groups-unconfirmed": "Kuittaamattomat/Yhteensä",
  "alerts-groups-show-all": "Näytä hälyt",
  "alerts-groups-hide-all": "Piilota hälyt",
  "alerts-timestamp": "Aikaleima",
  "alerts-configuration-name": "Nimi",
  "alerts-transactions": "Tranmsaktiot",
  "alerts-no-alerts": "Yhtään hälytystä ei ole tehty.",
  "alert-title": "Hälytys",
  "alerts-alert-ack": "Acknowledge",
  "alerts-flow-ack": "Acknowledge Flow",
  "alerts-ack-failed": "Hälyn ",
  "alerts-ack-all-failed": "Failed to acknowledge all alerts",
  "alerts-ack-trx-failed": "Failed to acknowledge the transaction",
  "alerts-ack-flow-failed": "Failed to acknowledge the flow",
  "alerts-alert-ack-all": "Acknowledge All",
  "alerts-alert-ack-all-question": "Really acknowledge all?",
  "alerts-get-alert-error": "Failed to get the alert",
  "alerts-get-transactions-error": "Failed to get the transcations for this alert",
  "alert-ack-all": "Acknowledge whole Alert",
  "alert-ack-all-question": "This will mark the whole Alert as acknowledged. Are you sure?",
  "alerts-pagination-failed": "Halyjen haku epäonnistui.",
  "alert-configuration-custom-email-placeholder": "Lisää hälyn vastaanottajia",
  "alert-configuration-management-selected-users": "Sähköpostitosoitteet:",
  "alert-configuration-no-emails": "Yhtään sähköpostiosoitetta ei ole vielä lisätty.",
  "alert-configuration-no-applications-selected": "Ole hyvä, ja valitse ainakin yksi aplikaatio.",
  "alert-configuration-no-operations-selected": "Ole hyvä, ja valitse ainakin yksi operaatio.",
  "alert-configuration-email-sending": "When to send e-mails",
  "alert-configuration-email-sending-every": "Send e-mail notification from every alert",
  "alert-configuration-email-sending-change":
    "Send e-mail notification only when the state of the alert changes (no alert to alert and vice versa)",
  "alert-configuration-management-active-alerts": "Aktiiviset Hälyt: {{current}} / {{max}}",
  "alerts-interval": "Intervalli: {{interval}}",
  "alerts-identifiers": "Identifier: {{ids}}",
  "alerts-status": "Status: {{status}}",
  "alerts-applications": "Applikaatiot: {{applications}}",
  "alerts-operations": "Operaatiot: {{operations}}",
  "alerts-all-operations": "Kaikki operaatiot ovat mukana.",
  "alerts-all-applications": "Kaikki applikaatiot ovat mukana.",
  "alert-configuration-Mon": "Ma",
  "alert-configuration-Tue": "Ti",
  "alert-configuration-Wed": "Ke",
  "alert-configuration-Thu": "To",
  "alert-configuration-Fri": "Pe",
  "alert-configuration-Sat": "La",
  "alert-configuration-Sun": "Su",
  "alert-configuration-schedule": "Schedule",
  "alert-configuration-active-days": "Aktiiviset Päivät",
  "alert-configuration-active-hours": "Aktiiviset Tunnit",
  "alert-configuration-active-hours-enabled": "Päällä:",
  "alert-configuration-timezone": "Aikavyöhyke",
  "alerts-job-timeframe": "The alert was triggered within this time period",
  "alerts-load-failed": "Failed to load Alert Configurations"
};
export { alertFi };
