import { BaseService } from "./base.service";

export class AlertService extends BaseService {
  constructor(Restangular) {
    "ngInject";

    super();

    this.instance = Restangular.service("instance");
  }

  getAlertConfs(instanceId) {
    return super.plainData(this.instance.one(instanceId).one("alertconfiguration").get());
  }

  getUnresolvedCount(instanceId) {
    return super.plainData(
      this.getPath(instanceId)
        .one("unresolvedcount")
        .get()
    );
  }

  getPath(instanceId) {
    return this.instance.one(instanceId).one("alert");
  }
}
