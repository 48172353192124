import { Elm } from "../../../../../aino.elmproj";
import { createElmApp, subscribe } from "../../../utils/ElmUtils";

export class ElmErrorsController {
  constructor($rootScope, $state, $localStorage, $translate, instance, errors, warnings, settings, errorCount, Restangular) {
    "ngInject";

    const app = createElmApp($rootScope, $state, Elm.Pages.Instance.Errors.Page, "elm-errors", {
      token: $localStorage.accessToken || null,
      translations: JSON.stringify($translate.getTranslationTable()),
      instance,
      errors,
      warnings,
      settings
    }, Restangular, $localStorage);

    subscribe(app, "clearErrorCount", () => {
      errorCount.errorCount = 0;
      $rootScope.$apply();
    });
  }
}
