import "./application-volume-table.scss";
import template from "./application-volume-table.html";

export function ApplicationVolumeTableDirective() {
  "ngInject";

  return {
    bindToController: {
      volumeStats: "<",
      getDisplayName: "&"
    },
    controller: function() {
      this.getTotalFailureRatio = (stat) => (stat.volume > 0 ? stat.failure / stat.volume * 100 : 0);

      this.getTotalUnknownRatio = (stat) => (stat.volume > 0 ? stat.unknown / stat.volume * 100 : 0);
    },
    controllerAs: "ctrl",
    template
  };
}
