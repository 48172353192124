import "./timeline.scss";
import { Elm } from "../../../../../aino.elmproj";
import { createElmApp, subscribe } from "../../../utils/ElmUtils";
import moment from "moment-timezone";
import toMoment from "../../../utils/MomentUtils";
import { isEmpty } from "lodash";

export class ElmTimelineController {
  constructor($scope, $rootScope, $state, $transition$, $location, $localStorage, $translate, instance, enums, Restangular) {
    "ngInject";

    const flags = {
      token: $localStorage.accessToken || null,
      translations: JSON.stringify($translate.getTranslationTable()),
      instance,
      enums,
      startTime: $transition$.params().startTime
        ? toMoment($transition$.params().startTime).format()
        : moment()
          .startOf("month")
          .format(),
      timeNow: moment().format(),
      urlParams: $transition$.params(),
      timeZone: moment.tz.guess()
    };
    readTimelineMaxValue(flags);
    readTimelineShowTotals(flags);

    const app = createElmApp($rootScope, $state, Elm.Pages.Instance.Timeline.Page, "elm-timeline", flags,
      Restangular, $localStorage);

    loadSavedFilters();

    const unregisterFn = $rootScope.$on("locationChangeSuccess", (_, search) => {
      if (!isEmpty(search)) {
        app.ports.updateTimelineUrlParams.send(search);
      }
    });
    $scope.$on("$destroy", unregisterFn);

    subscribe(app, "setSearchParams", (searchParams) => {
      $scope.$apply(() => $location.search(createSearchObject(searchParams)));
    });

    subscribe(app, "saveFilters", (filters) => {
      if (!$localStorage.savedFilters || !$localStorage.savedFilters[instance._id]) {
        $localStorage.savedFilters = {};
      }
      $localStorage.savedFilters[instance._id] = filters;
    });

    subscribe(app, "saveTimelineShowTotals", (show) => {
      const timeline = getTimelineFromLocalStorage();
      timeline.showTotals = show;
      $localStorage[instance._id].timeline = timeline;
    });

    subscribe(app, "saveTimelineMaxValue", (maxValue) => {
      const timeline = getTimelineFromLocalStorage();
      timeline.maxValue = maxValue;
      $localStorage[instance._id].timeline = timeline;
    });

    subscribe(app, "removeTimelineMaxValue", () => {
      const timeline = getTimelineFromLocalStorage();
      delete timeline.maxValue;
      $localStorage[instance._id].timeline = timeline;
    });

    function getTimelineFromLocalStorage() {
      if (!$localStorage[instance._id]) {
        $localStorage[instance._id] = {};
      }
      if (!$localStorage[instance._id].timeline) {
        $localStorage[instance._id].timeline = {};
      }
      const timeline = $localStorage[instance._id].timeline || {};
      $localStorage[instance._id].timeline = timeline;
      return timeline;
    }

    function loadSavedFilters() {
      if ($localStorage.savedFilters) {
        if ($localStorage.savedFilters[instance._id]) {
          app.ports.updateFilters.send($localStorage.savedFilters[instance._id]);
        }
      } else {
        $localStorage.savedFilters = {};
      }
    }

    function readTimelineMaxValue(flags) {
      if (
        $localStorage[instance._id] &&
        $localStorage[instance._id].timeline &&
        $localStorage[instance._id].timeline.maxValue
      ) {
        flags.timelineMaxValue = parseInt($localStorage[instance._id].timeline.maxValue, 10);
      }
    }

    function readTimelineShowTotals(flags) {
      if (
        $localStorage[instance._id] &&
        $localStorage[instance._id].timeline &&
        $localStorage[instance._id].timeline.showTotals
      ) {
        flags.showTotals = $localStorage[instance._id].timeline.showTotals;
      }
    }

    function createSearchObject({
                                  startTime,
                                  status,
                                  applications,
                                  operations,
                                  integrationSegments,
                                  searchFilterOpen,
                                  searchString,
                                  resolution,
                                  scale,
                                  customTopic,
                                  showTransactionList
                                }) {
      return {
        startTime,
        status: status ? status.join(",") : null,
        searchFilterOpen: searchFilterOpen || null,
        searchString: searchString || null,
        applications: applications ? applications.join(",") : null,
        operations: operations ? operations.join(",") : null,
        integrationSegments: integrationSegments ? integrationSegments.join(",") : null,
        resolution,
        scale,
        customTopic: customTopic || null,
        showTransactionList: showTransactionList || null
      };
    }
  }
}
