import { BaseService } from "./base.service";

export class PlanService extends BaseService {
  constructor(Restangular, $log) {
    "ngInject";
    super();
    this.$log = $log;
    this.plan = Restangular.service("plan");
  }

  getPlans() {
    return super.plainData(this.plan.getList());
  }
}

