import moment from "moment-timezone";

export function routerInstanceTimelineConfig($stateProvider) {
  "ngInject";

  $stateProvider
    .state("instance.timeline", {
      url:
        "/timeline?resolution?startTime?searchString?status?applications?operations?integrationSegments?searchFilterOpen?pageNumber?scale?customTopic?showTransactionList",
      views: {
        content: {
          template: require("../../features/instance/timeline/template.html").default,
          controller: "ElmTimelineController"
        }
      },
      reloadOnSearch: false,
      dynamic: true,
      data: { authenticated: true },
      params: {
        searchString: {
          value: ""
        },
        status: {
          value: null
        },
        applications: {
          value: null
        },
        operations: {
          value: null
        },
        integrationSegments: {
          value: null
        },
        searchFilterOpen: {
          value: false,
          type: "bool"
        },
        pageNumber: {
          value: "1"
        },
        resolution: {
          value: "day"
        },
        startTime: {
          value: moment()
            .startOf("month")
            .valueOf(),
          type: "int"
        },
        scale: {
          value: null
        },
        customTopic: {
          value: null
        },
        showTransactionList: {
          value: null
        }
      }
    })
    .state("instance.flowtimeline", {
      url: "/flowtimeline?resolution?searchString?startTime?status?lastStatus?applications?operations?integrationSegments?searchFilterOpen?scale?flowTimeChartScaleSeconds?flowTimeChartScaleMinutes?customTopic?flowIds?showFlowList?idTypeFilter?flowVolumeType?sort?sortOrder",
      views: {
        content: {
          template: require("../../features/instance/flowtimeline/template.html").default,
          controller: "ElmFlowtimelineController"
        }
      },
      resolve: {
        namedFlows: (IdentifiedFlowService, $transition$) => IdentifiedFlowService.getShowInFiltersFlows($transition$.params().instanceId)
      },
      reloadOnSearch: false,
      dynamic: true,
      data: { authenticated: true },
      params: {
        searchString: {
          value: ""
        },
        status: {
          value: null
        },
        lastStatus: {
          value: null
        },
        applications: {
          value: null
        },
        operations: {
          value: null
        },
        integrationSegments: {
          value: null
        },
        searchFilterOpen: {
          value: false,
          type: "bool"
        },
        pageNumber: {
          value: "1"
        },
        resolution: {
          value: "day"
        },
        startTime: {
          value: moment()
            .startOf("month")
            .valueOf(),
          type: "int"
        },
        scale: {
          value: null
        },
        flowTimeChartScaleSeconds: {
          value: null
        },
        flowTimeChartScaleMinutes: {
          value: null
        },
        customTopic: {
          value: null
        },
        flowIds: {
          value: null
        },
        showFlowList: {
          value: null
        },
        idTypeFilter: {
          value: null
        },
        flowVolumeType: {
          value: null
        },
        sort: {
          value: null
        },
        sortOrder: {
          value: null
        }
      }
    })
    .state("instance.identifiedFlows", {
      url: "/identifiedflows?applications?operations?pageNumber?searchFilterOpen?flowIds",
      views: {
        content: {
          template: require("../../features/instance/identifiedFlows/template.html").default,
          controller: "ElmIdentifiedFlowsController"
        }
      },
      reloadOnSearch: false,
      dynamic: true,
      data: { authenticated: true },
      params: {
        applications: {
          value: null
        },
        operations: {
          value: null
        },
        pageNumber: {
          value: "1"
        },
        searchFilterOpen: {
          value: false,
          type: "bool"
        },
        flowIds: {
          value: null
        }
      }
    })
    .state("instance.identifiedFlow", {
      url: "/identifiedflow/:id?selectedTab",
      views: {
        content: {
          template: require("../../features/instance/identifiedFlow/template.html").default,
          controller: "ElmIdentifiedFlowController"
        }
      },
      reloadOnSearch: false,
      dynamic: true,
      data: { authenticated: true },
      params: {
        selectedTab: {
          value: null
        }
      }
    })
    .state("instance.linkedFlows", {
      url: "/linkedflows",
      views: {
        content: {
          template: require("../../features/instance/linkedFlows/template.html").default,
          controller: "ElmLinkedFlowsController"
        }
      },
      reloadOnSearch: false,
      dynamic: true,
      data: { authenticated: true }
    })
    .state("instance.linkedFlow", {
      url: "/linkedflow/:id",
      views: {
        content: {
          template: require("../../features/instance/linkedFlow/template.html").default,
          controller: "ElmLinkedFlowController"
        }
      },
      reloadOnSearch: false,
      dynamic: true,
      data: { authenticated: true }
    });
}
