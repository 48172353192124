export function createElmApp($rootScope, $state, Elm, elementId, flags, Restangular, $localStorage) {
  const app = Elm.init({ node: document.getElementById(elementId), flags });

  subscribe(app, "showErrorMessage", (message) => {
    $rootScope.flashErrors.push(message);
    $rootScope.$apply();
  });

  subscribe(app, "showFlashMessage", (message) => {
    $rootScope.flashMessages.push(message);
    $rootScope.$apply();
  });

  subscribe(app, "consoleLog", (message) => console.log(message));

  subscribe(app, "consoleError", (message) => console.error(message));

  subscribe(app, "toState", (obj) => {
    $state.go(obj.state, obj.options);
  });

  subscribe(app, "selectInputText", (message) => {
    const input = document.getElementById(message);
    if (input) {
      input.focus();
      input.select();
    }
  });

  subscribe(app, "popPreviousState", () => {
    const prevState = $rootScope.previousStates.pop();
    $rootScope.previousStateParams.pop();
  });

  subscribe(app, "toPreviousState", () => {
    if ($rootScope.previousStates.length > 0) {
      const previousState = $rootScope.previousStates.pop();
      const previousParams = $rootScope.previousStateParams.pop();

      $state.go(previousState, previousParams);
    }
  });

  subscribe(app, "toPreviousStateWithParams", params => {
    if (!isEmpty($rootScope.previousState.name)) {
      const prevParams = {
        ...$rootScope.previousStateParams,
        ...params
      };

      $state.go(previousState, prevParams);
    }
  });

  subscribe(app, "clearToken", () => {
    Restangular.setDefaultHeaders({ Authorization: null });
    $localStorage.accessToken = '';
  });

  return app;
}

export function subscribe(app, name, func) {
  if (app.ports[name]) {
    app.ports[name].subscribe(func);
  }
}
