import "./flowtimeline.scss";
import { Elm } from "../../../../../aino.elmproj";
import { createElmApp, subscribe } from "../../../utils/ElmUtils";
import moment from "moment-timezone";
import toMoment from "../../../utils/MomentUtils";
import { isEmpty, isNil } from "lodash";

function getParentFlows(namedFlows) {
  const parentFlows = namedFlows.filter(f => isNil(f.parent));
  return parentFlows.map(parentFlow => {
    const subFlows = namedFlows.filter(f => {
      return !isNil(f.parent) && f.parent.toString() === parentFlow._id.toString();
    })
      .map(f => f._id);
    return {
      _id: parentFlow._id,
      name: parentFlow.name,
      subFlows
    };
  });
}

export class ElmFlowtimelineController {
  constructor($scope, $rootScope, $state, $transition$, $location, $localStorage, $translate, instance, enums,
    namedFlows, Restangular) {
    "ngInject";

    const parentFlows = getParentFlows(namedFlows);
    const subFlows = namedFlows.filter(f => !isNil(f.parent));

    const flags = {
      token: $localStorage.accessToken || null,
      translations: JSON.stringify($translate.getTranslationTable()),
      instance,
      enums,
      startTime: $transition$.params().startTime
        ? toMoment($transition$.params().startTime).format()
        : moment()
          .startOf("month")
          .format(),
      timeNow: moment().format(),
      urlParams: $transition$.params(),
      timeZone: moment.tz.guess(),
      namedFlows: parentFlows,
      namedSubFlows: subFlows
    };
    readTimelineMaxValue(flags);
    readFlowTimeChartMaxMinutes(flags);
    readFlowTimeChartMaxSeconds(flags);
    readTimelineShowTotals(flags);

    const app = createElmApp($rootScope, $state, Elm.Pages.Instance.FlowTimeline.Page, "elm-flowtimeline",
      flags, Restangular, $localStorage);

    loadSavedFilters();
    loadFlowTimelineSelections();

    const unregisterFn = $rootScope.$on("locationChangeSuccess", (_, search) => {
      if (!isEmpty(search)) {
        app.ports.updateTimelineUrlParams.send(search);
      }
    });
    $scope.$on("$destroy", unregisterFn);

    subscribe(app, "setSearchParams", (searchParams) => {
      $scope.$apply(() => $location.search(createSearchObject(searchParams)));
    });

    subscribe(app, "saveFilters", (filters) => {
      if (!$localStorage.savedFilters || !$localStorage.savedFilters[instance._id]) {
        $localStorage.savedFilters = {};
      }
      $localStorage.savedFilters[instance._id] = filters;
    });

    subscribe(app, "saveTimelineShowTotals", (show) => {
      const timeline = getTimelineFromLocalStorage();
      timeline.showTotals = show;
      $localStorage[instance._id].timeline = timeline;
    });

    subscribe(app, "saveTimelineMaxValue", (maxValue) => {
      const timeline = getTimelineFromLocalStorage();
      timeline.maxValue = maxValue;
      $localStorage[instance._id].timeline = timeline;
    });

    subscribe(app, "removeTimelineMaxValue", () => {
      const timeline = getTimelineFromLocalStorage();
      delete timeline.maxValue;
      $localStorage[instance._id].timeline = timeline;
    });

    subscribe(app, "saveFlowTimelineTimeSelections", selections => {
      const flowTimeline = getFlowTimelineFromLocalStorage();
      flowTimeline.selections = selections;
      $localStorage[instance._id].flowTimeline = flowTimeline;
    });

    subscribe(app, "saveFlowTimeChartMaxMinutesValue", (maxValue) => {
      const timeChart = getFlowTimeChartFromLocalStorage();
      timeChart.maxMinutes = maxValue;
      $localStorage[instance._id].timeline = timeChart;
    });

    subscribe(app, "removeFlowTimeChartMaxMinutesValue", () => {
      const timeChart = getFlowTimeChartFromLocalStorage();
      delete timeChart.maxMinutes;
      $localStorage[instance._id].timeline = timeChart;
    });

    subscribe(app, "saveFlowTimeChartMaxSecondsValue", (maxValue) => {
      const timeChart = getFlowTimeChartFromLocalStorage();
      timeChart.maxSeconds = maxValue;
      $localStorage[instance._id].timeline = timeChart;
    });

    subscribe(app, "removeFlowTimeChartMaxSecondsValue", () => {
      const timeChart = getFlowTimeChartFromLocalStorage();
      delete timeChart.maxSeconds;
      $localStorage[instance._id].timeline = timeChart;
    });

    function getTimelineFromLocalStorage() {
      if (!$localStorage[instance._id]) {
        $localStorage[instance._id] = {};
      }
      if (!$localStorage[instance._id].timeline) {
        $localStorage[instance._id].timeline = {};
      }
      const timeline = $localStorage[instance._id].timeline || {};
      $localStorage[instance._id].timeline = timeline;
      return timeline;
    }

    function getFlowTimelineFromLocalStorage() {
      if (!$localStorage[instance._id]) {
        $localStorage[instance._id] = {};
      }
      if (!$localStorage[instance._id].flowTimeline) {
        $localStorage[instance._id].flowTimeline = {};
      }
      return $localStorage[instance._id].flowTimeline || {};
    }

    function getFlowTimeChartFromLocalStorage() {
      if (!$localStorage[instance._id]) {
        $localStorage[instance._id] = {};
      }
      if (!$localStorage[instance._id].flowTimeChart) {
        $localStorage[instance._id].flowTimeChart = {};
      }
      return $localStorage[instance._id].flowTimeChart || {};
    }

    function loadSavedFilters() {
      if ($localStorage.savedFilters) {
        if ($localStorage.savedFilters[instance._id]) {
          app.ports.updateFilters.send($localStorage.savedFilters[instance._id]);
        }
      } else {
        $localStorage.savedFilters = {};
      }
    }

    function loadFlowTimelineSelections() {
      const flowTimeline = getFlowTimelineFromLocalStorage();
      if (flowTimeline.selections) {
        app.ports.loadFlowTimelineTimeSelections.send(flowTimeline.selections);
      }
    }

    function readTimelineMaxValue(flags) {
      if (
        $localStorage[instance._id] &&
        $localStorage[instance._id].timeline &&
        $localStorage[instance._id].timeline.maxValue
      ) {
        flags.timelineMaxValue = parseInt($localStorage[instance._id].timeline.maxValue, 10);
      }
    }

    function readFlowTimeChartMaxMinutes(flags) {
      if (
        $localStorage[instance._id] &&
        $localStorage[instance._id].flowTimeChart &&
        $localStorage[instance._id].flowTimeChart.maxMinutes
      ) {
        flags.flowTimeChartMaxMinutes = parseInt($localStorage[instance._id].flowTimeChart.maxMinutes, 10);
      }
    }

    function readFlowTimeChartMaxSeconds(flags) {
      if (
        $localStorage[instance._id] &&
        $localStorage[instance._id].flowTimeChart &&
        $localStorage[instance._id].flowTimeChart.maxSeconds
      ) {
        flags.flowTimeChartMaxSeconds = parseInt($localStorage[instance._id].flowTimeChart.maxSeconds, 10);
      }
    }

    function readTimelineShowTotals(flags) {
      if (
        $localStorage[instance._id] &&
        $localStorage[instance._id].timeline &&
        $localStorage[instance._id].timeline.showTotals
      ) {
        flags.showTotals = $localStorage[instance._id].timeline.showTotals;
      }
    }

    function createSearchObject({
      startTime,
      status,
      lastStatus,
      applications,
      operations,
      integrationSegments,
      pageNumber,
      searchFilterOpen,
      searchString,
      showFlows,
      resolution,
      scale,
      flowTimeChartScaleMinutes,
      flowTimeChartScaleSeconds,
      customTopic,
      flowIds,
      showFlowList,
      idTypeFilter,
      flowVolumeType,
      sort,
      sortOrder
    }) {
      return {
        startTime,
        status: status ? status.join(",") : null,
        lastStatus: lastStatus ? lastStatus.join(",") : null,
        searchFilterOpen: searchFilterOpen || null,
        searchString: searchString || null,
        applications: applications ? applications.join(",") : null,
        operations: operations ? operations.join(",") : null,
        integrationSegments: integrationSegments ? integrationSegments.join(",") : null,
        pageNumber,
        showFlows: showFlows || null,
        resolution,
        scale,
        flowTimeChartScaleMinutes: flowTimeChartScaleMinutes || null,
        flowTimeChartScaleSeconds: flowTimeChartScaleSeconds || null,
        customTopic: customTopic || null,
        flowIds: flowIds ? flowIds.join(",") : null,
        showFlowList: showFlowList || null,
        idTypeFilter: idTypeFilter || null,
        flowVolumeType: flowVolumeType || null,
        sort: sort || null,
        sortOrder: sortOrder || null
      };
    }
  }
}
