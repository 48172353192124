import { config } from "./config/config";
import { i18nConfig } from "./config/i18n/i18n.config";
import { restangularConfig } from "./config/restangular.config";
import { routerConfig } from "./config/route/route";
import { routerInstanceConfig } from "./config/route/route.instance";
import { routerInstanceTransactionConfig } from "./config/route/route.instance.transaction";
import { routerInstanceExportConfig } from "./config/route/route.instance.export";
import { routerInstanceAlertsConfig } from "./config/route/route.instance.alerts";
import { routerInstanceAdminConfig } from "./config/route/route.instance.admin";
import { routerInstanceTimelineConfig } from "./config/route/route.instance.timeline";
import { runBlock } from "./index.run";

import { ElmLoginController } from "./features/login/elm-login.controller";
import { LogoutController } from "./features/logout/logout.controller";
import { ElmDashboardController } from "./features/dashboard/elm-dashboard.controller";
import { InstanceController } from "./features/instance/instance.controller";
import { ElmTransactionController } from "./features/instance/transaction/elm-transaction.controller";
import { ElmErrorsController } from "./features/instance/errors/elm-errors.controller";
import { ElmErrorController } from "./features/instance/error/elm-error.controller";
import { ElmWarningController } from "./features/instance/warning/elm-warning.controller";
import { ElmApplicationsController } from "./features/instance/applications/elm-applications.controller";
import { ElmOperationsController } from "./features/instance/operations/elm-operations.controller";
import { ElmTimelineController } from "./features/instance/timeline/elm-timeline.controller";
import { ElmTransactionExportController } from "./features/instance/transactionExport/elm-transaction-export.controller";
import { ElmFlowExportController } from "./features/instance/flowExport/elm-flow-export.controller";
import { ElmAlertExportController } from "./features/instance/alertExport/elm-alert-export.controller";
import { ElmFlowtimelineController } from "./features/instance/flowtimeline/elm-flowtimeline.controller";
import { ElmIdentifiedFlowsController } from "./features/instance/identifiedFlows/elm-identifiedflows.controller";
import { ElmIdentifiedFlowController } from "./features/instance/identifiedFlow/elm-identifiedflow.controller";
import { ElmAlertsController } from "./features/instance/alerts/elm-alerts.controller";
import { OverviewController } from "./features/instance/overview/overview.controller";
import { ElmRegisterController } from "./features/register/elm-register.controller";
import { TosController } from "./features/tos/tos.controller";
import { StyleGuideController } from "./features/style-guide/style-guide.controller";
import { ElmLinkedFlowsController } from "./features/instance/linkedFlows/elm-linkedFlows.controller";
import { ElmLinkedFlowController } from "./features/instance/linkedFlow/elm-linkedFlow.controller";

import { ConfirmController } from "./features/confirm/confirm.controller";
import { ElmPasswordRecoveryController } from "./features/passwordrecovery/elm-passwordrecovery.controller";
import { ElmPasswordResetController } from "./features/passwordreset/elm-passwordreset.controller";
import { UsersController } from "./features/instance/admin/users/users.controller";
import { ElmApiAccessController } from "./features/instance/admin/api-access/elm-api-access.controller";

import { UserService } from "./components/service/user.service";
import { CustomershipService } from "./components/service/customership.service";
import { InstanceService } from "./components/service/instance.service";
import { ErrorInterceptorService } from "./components/service/error-interceptor.service";
import { TransactionSummaryService } from "./components/service/transaction-summary.service";
import { AlertService } from "./components/service/alert.service";
import { InvitationService } from "./components/service/invitation.service";
import { AccountService } from "./components/service/account.service";
import { PlanService } from "./components/service/plan.service";
import { SettingsService } from "./components/service/settings.service";
import { IdentifiedFlowService } from "./components/service/identified-flow.service";

import { FlashMessageDirective } from "./components/directive/flash-message/flash-message.directive";
import { BarChartDirective } from "./components/directive/bar-chart/bar-chart.directive";
import { ConfirmDirective } from "./components/directive/confirm/confirm.directive";
import { EmailValidatorDirective } from "./components/directive/email-validator/email-validator.directive";
import { PaginationDirective } from "./components/directive/pagination/pagination.directive";
import { NetworkGraphDirective } from "./components/directive/network-graph/network-graph.directive";
import { TimeframeSelectorDirective } from "./components/directive/timeframe-selector/timeframe-selector.directive";
import { OperationVolumeTableDirective } from "./components/directive/operation-volume-table/operation-volume-table.directive";
import { ApplicationVolumeTableDirective } from "./components/directive/application-volume-table/application-volume-table.directive";
import { FilterContainerDirective } from "./components/directive/filter/filter-container.directive";
import { EnumFilterDirective } from "./components/directive/filter/enum/enum-filter.directive";
import { ProblematicEnumTableDirective } from "./components/directive/problematic-enum-table/problematicEnumTable.directive";
import { PasswordInputDirective } from "./components/directive/form-input/password-input/password-input.directive";

import truncate from "./components/filter/truncate";

import sceStrategy from "./components/factory/sce_strategy";
import angular from "angular";

const ainoApp = angular
  .module("ainoUi", [
    "ngAnimate",
    "ngCookies",
    "ngSanitize",
    "ngMessages",
    "ngAria",
    "restangular",
    "ui.router",
    "toastr",
    "ngStorage",
    "angularjs-datetime-picker",
    "pascalprecht.translate",
    "angularMoment",
    "ngNumeraljs",
    "ngMdIcons",
    "rx"
  ])
  .factory("sceStrategy", sceStrategy)
  .config(config)
  .config(restangularConfig)
  .config(i18nConfig)
  .config(routerConfig)
  .config(routerInstanceConfig)
  .config(routerInstanceTransactionConfig)
  .config(routerInstanceExportConfig)
  .config(routerInstanceAlertsConfig)
  .config(routerInstanceAdminConfig)
  .config(routerInstanceTimelineConfig)
  .run(runBlock)
  .service("UserService", UserService)
  .service("CustomershipService", CustomershipService)
  .service("InstanceService", InstanceService)
  .service("ErrorInterceptorService", ErrorInterceptorService)
  .service("TransactionSummaryService", TransactionSummaryService)
  .service("AlertService", AlertService)
  .service("InvitationService", InvitationService)
  .service("AccountService", AccountService)
  .service("PlanService", PlanService)
  .service("SettingsService", SettingsService)
  .service("IdentifiedFlowService", IdentifiedFlowService)
  .controller("ElmLoginController", ElmLoginController)
  .controller("LogoutController", LogoutController)
  .controller("ElmDashboardController", ElmDashboardController)
  .controller("InstanceController", InstanceController)
  .controller("ElmTransactionController", ElmTransactionController)
  .controller("ElmErrorsController", ElmErrorsController)
  .controller("ElmErrorController", ElmErrorController)
  .controller("ElmWarningController", ElmWarningController)
  .controller("ElmApplicationsController", ElmApplicationsController)
  .controller("ElmOperationsController", ElmOperationsController)
  .controller("ElmTimelineController", ElmTimelineController)
  .controller("ElmTransactionExportController", ElmTransactionExportController)
  .controller("ElmFlowExportController", ElmFlowExportController)
  .controller("ElmAlertExportController", ElmAlertExportController)
  .controller("ElmAlertsController", ElmAlertsController)
  .controller("ElmRegisterController", ElmRegisterController)
  .controller("ConfirmController", ConfirmController)
  .controller("ElmPasswordRecoveryController", ElmPasswordRecoveryController)
  .controller("ElmPasswordResetController", ElmPasswordResetController)
  .controller("UsersController", UsersController)
  .controller("ApiAccessController", ElmApiAccessController)
  .controller("TosController", TosController)
  .controller("OverviewController", OverviewController)
  .controller("StyleGuideController", StyleGuideController)
  .controller("ElmFlowtimelineController", ElmFlowtimelineController)
  .controller("ElmIdentifiedFlowsController", ElmIdentifiedFlowsController)
  .controller("ElmIdentifiedFlowController", ElmIdentifiedFlowController)
  .controller("ElmLinkedFlowsController", ElmLinkedFlowsController)
  .controller("ElmLinkedFlowController", ElmLinkedFlowController)
  // ATTENTION!! Directive names have to start with a lowercase letter.
  .directive("flashMessage", FlashMessageDirective)
  .directive("barChart", BarChartDirective)
  .directive("confirm", ConfirmDirective)
  .directive("emailValidator", EmailValidatorDirective)
  .directive("pagination", PaginationDirective)
  .directive("timeframeSelector", TimeframeSelectorDirective)
  .directive("networkGraph", NetworkGraphDirective)
  .directive("operationVolumeTable", OperationVolumeTableDirective)
  .directive("applicationVolumeTable", ApplicationVolumeTableDirective)
  .directive("filterContainer", FilterContainerDirective)
  .directive("enumFilter", EnumFilterDirective)
  .directive("passwordInput", PasswordInputDirective)
  .directive("problematicEnumTable", ProblematicEnumTableDirective)
  .filter("characters", truncate);

angular.element(document).ready(() => {
  const initInjector = angular.injector(["ng", "restangular", "ngStorage"]);

  const localStorage = initInjector.get("$localStorage");

  if (!localStorage.accessToken) {
    ainoApp.constant("preFetchedUser", null);
    angular.bootstrap(document, ["ainoUi"]);
  } else {
    const restangular = initInjector.get("Restangular");

    restangular.setDefaultHeaders({ Authorization: "JWT " + localStorage.accessToken });
    restangular
      .oneUrl("user", "/rest/v1/user")
      .get()
      .then((user) => ainoApp.constant("preFetchedUser", user))
      .catch(() => ainoApp.constant("preFetchedUser", null))
      .finally(() => angular.bootstrap(document, ["ainoUi"]));
  }
});
