import "./dashboard.scss";
import { Elm } from "../../../../aino.elmproj";
import { createElmApp } from "../../utils/ElmUtils";
import moment from "moment-timezone";

export class ElmDashboardController {
  constructor($translate, $localStorage, $rootScope, $state, Restangular) {
    "ngInject";

    createElmApp($rootScope, $state, Elm.Main, "elm-dashboard", {
      page: "Dashboard",
      token: $localStorage.accessToken || null,
      translations: JSON.stringify($translate.getTranslationTable()),
      timeNow: moment().valueOf(),
      timeZone: moment.tz.guess()
    }, Restangular, $localStorage);
  }
}
