export function routerInstanceTransactionConfig($stateProvider) {
  "ngInject";

  $stateProvider
    .state("instance.transaction", {
      url: "/transaction/:transactionId?searchString",
      views: {
        content: {
          template: require("../../features/instance/transaction/template.html").default,
          controller: "ElmTransactionController"
        }
      },
      data: { authenticated: true },
      params: {
        searchString: {
          value: null
        }
      }
    });
}
