export function routerInstanceExportConfig($stateProvider) {
  "ngInject";

  $stateProvider
    .state("instance.transactionExport", {
      url: "/transactionExport",
      views: {
        content: {
          template: require("../../features/instance/transactionExport/template.html").default,
          controller: "ElmTransactionExportController"
        }
      },
      reloadOnSearch: false,
      dynamic: true,
      data: { authenticated: true }
    })
    .state("instance.flowExport", {
      url: "/flowExport",
      views: {
        content: {
          template: require("../../features/instance/flowExport/template.html").default,
          controller: "ElmFlowExportController"
        }
      },
      resolve: {
        namedFlows: (IdentifiedFlowService, $transition$) => IdentifiedFlowService.getShowInFiltersFlows($transition$.params().instanceId)
      },
      reloadOnSearch: false,
      dynamic: true,
      data: { authenticated: true }
    })
    .state("instance.alertExport", {
      url: "/alertExport?alertId?startTime?endTime?groupId",
      views: {
        content: {
          template: require("../../features/instance/alertExport/template.html").default,
          controller: "ElmAlertExportController"
        }
      },
      resolve: {
        alertConfs: (AlertService, $transition$) => AlertService.getAlertConfs($transition$.params().instanceId)
      },
      reloadOnSearch: false,
      dynamic: true,
      data: { authenticated: true },
      params: {
        alertId: {
          value: null
        },
        groupId: {
          value: null
        },
        startTime: {
          value: null
        },
        endTime: {
          value: null
        }
      }
    });
}
