import { BaseService } from "./base.service";

export class SettingsService extends BaseService {
  constructor(Restangular) {
    "ngInject";

    super();

    this.instance = Restangular.service("instance");
  }

  getPath(instanceId) {
    return this.instance.one(instanceId).one("settings");
  }

  getErrorSettings(instanceId) {
    return super.plainData(
      this.getPath(instanceId)
        .one("error-times")
        .get()
    );
  }
}
