import "./login.scss";
import { Elm } from "../../../../aino.elmproj";
import { createElmApp } from "../../utils/ElmUtils";
import moment from "moment-timezone";

export function ElmLoginController(Restangular, $localStorage, $translate, $rootScope, $transition$, $state) {
  "ngInject";

  const app = createElmApp($rootScope, $state, Elm.Main, "elm-login", {
    page: "Login",
    token: $localStorage.accessToken || null,
    translations: JSON.stringify($translate.getTranslationTable()),
    timeNow: moment().valueOf(),
    timeZone: moment.tz.guess()
  }, Restangular, $localStorage);

  app.ports.changeState.subscribe(() => {
    $state.go($transition$.params().toState || "dashboard", $transition$.params().toParams);
  });

  app.ports.setToken.subscribe((token) => {
    Restangular.setDefaultHeaders({ Authorization: "JWT " + token });
    $localStorage.accessToken = token;
  });

  app.ports.setUser.subscribe((user) => {
    $rootScope.user = user;
  });
}
