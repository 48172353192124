import "./timeframe-selector.scss";
import template from "./timeframe-selector.html";
import moment from "moment";

export function TimeframeSelectorDirective() {
  "ngInject";

  return {
    restrict: "E",
    scope: {
      selectedTimeframe: "=",
      startTime: "=",
      filterBackward: "&",
      filterForward: "&",
      filterToResolution: "&"
    },
    template,
    link: (scope) => {
      scope.shouldNextButtonBeDisabled = () =>
        moment().isBefore(moment(parseInt(scope.startTime, 10)).add(1, scope.selectedTimeframe));
    }
  };
}
