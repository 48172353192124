const usersFi = {
  "users-invite-user-plus": "+ Kutsu Ihmisiä",
  "users-invite-user-minus": "- Kutsu Ihmisiä",
  "users-invite-submit": "Kutsu",
  "users-invite-current-users": "Instanssin käyttäjät",
  "users-invite-invited-users": "Kutsutut Käyttäjät",
  "users-invitation-success": "Käyttäjä lisätty.",
  "users-invitation-failed": "Käyttäjän lisääminen epäonnistui. Yritä uudelleen.",
  "users-invitation-get-users-failed": "Failed to get user list. Please refresh page.",
  "users-cannot-invite-yourself": "Itsensä kutsuminen ei ole mahdollista.",
  "users-is-already-instance-user": "Käyttäjä on jo instanssissa.",
  "users-reinvite": "Kutsu uudelleen",
  "users-remove-invitation-failed": "Kutsun poisto epäonnistui."
};

export { usersFi };
