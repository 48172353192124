import "./tos.scss";

export class TosController {
  constructor($scope, $rootScope, $sce, $state, $transition$, CustomershipService, UserService) {
    "ngInject";

    this.scope = $scope;
    this.rootScope = $rootScope;
    this.state = $state;
    this.toState = $transition$.params().toState || "dashboard";
    this.toParams = $transition$.params().toParams;
    this.customershipService = CustomershipService;
    this.userService = UserService;
    this.acceptingTos = false;

    this.getTrustedSrc = () => $sce.trustAsResourceUrl(tosUrl);
  }

  acceptTos() {
    this.acceptingTos = true;

    this.customershipService
      .acceptTos()
      .then(() =>
        // We"ll need to update the user object, otherwise we"ll end up in the tos page again
        this.userService.getUser().then(() => this.state.go(this.toState, this.toParams))
      )
      .catch((_) => {
        this.rootScope.flashErrors.push("tos-accept-failed");
        this.acceptingTos = false;
        this.scope.$apply();
      });
  }
}
