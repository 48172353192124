export function routerConfig($stateProvider, $urlRouterProvider) {
  "ngInject";

  $stateProvider
    .state("dashboard", {
      url: "/dashboard",
      views: {
        "main-view": {
          template: require("../../features/dashboard/template.html").default,
          controller: "ElmDashboardController"
        }
      },
      data: {
        authenticated: true
      }
    })
    .state("login", {
      url: "/login",
      views: {
        "main-view": {
          template: require("../../features/login/template.html").default,
          controller: "ElmLoginController"
        }
      },
      data: {
        authenticated: false
      },
      params: {
        toState: null,
        toParams: null
      }
    })
    .state("register", {
      url: "/register/:invitationId",
      views: {
        "main-view": {
          template: require("../../features/register/template.html").default,
          controller: "ElmRegisterController"
        }
      },
      resolve: {
        invitation: (InvitationService, $transition$) =>
          InvitationService.getInvitation($transition$.params().invitationId)
      },
      data: {
        authenticated: false
      }
    })
    .state("confirm", {
      url: "/confirm/:token",
      views: {
        "main-view": {
          template: require("../../features/confirm/confirm.html").default,
          controller: "ConfirmController",
          controllerAs: "confirmCtrl"
        }
      },
      resolve: {
        confirmFailed: (UserService, $transition$, $rootScope, $state) =>
          UserService.confirm($transition$.params().token)
            .then(() => false)
            .catch((err) => {
              const message = err.data.message;
              if (message === "already_confirmed") {
                return true;
              }
              $rootScope.flashErrors.push("confirm-failed-text");
              return $state.go("forbidden");
            })
      },
      data: {
        authenticated: false
      }
    })
    .state("passwordrecovery", {
      url: "/passwordrecovery",
      views: {
        "main-view": {
          template: require("../../features/passwordrecovery/template.html").default,
          controller: "ElmPasswordRecoveryController"
        }
      },
      data: {
        authenticated: false
      }
    })
    .state("passwordreset", {
      url: "/passwordreset/:token",
      views: {
        "main-view": {
          template: require("../../features/passwordreset/template.html").default,
          controller: "ElmPasswordResetController"
        }
      },
      data: {
        authenticated: false
      }
    })
    .state("style-guide", {
      url: "/style-guide",
      views: {
        "main-view": {
          template: require("../../features/style-guide/style-guide.html").default,
          controller: "StyleGuideController"
        }
      },
      data: {
        authenticated: false
      }
    })
    .state("forbidden", {
      url: "/forbidden",
      views: {
        "main-view": {
          template: require("../../features/error/forbidden.html").default
        }
      },
      data: {
        authenticated: false
      }
    })
    .state("tos", {
      url: "/tos",
      views: {
        "main-view": {
          template: require("../../features/tos/tos.html").default,
          controller: "TosController",
          controllerAs: "tosCtrl"
        }
      },
      data: {
        authenticated: false
      },
      params: {
        toState: null,
        toParams: null
      }
    });

  $urlRouterProvider.otherwise("/login");
}
