import { Elm } from "../../../../../aino.elmproj";
import { createElmApp } from "../../../utils/ElmUtils";
import moment from "moment-timezone";

export class ElmAlertExportController {
  constructor($rootScope,
              $state,
              $transition$,
              $localStorage,
              $translate,
              instance,
              enums,
              Restangular,
              alertConfs) {
    "ngInject";

    createElmApp($rootScope, $state, Elm.Pages.Instance.AlertExport.Page, "elm-alert-export", {
      token: $localStorage.accessToken || null,
      translations: JSON.stringify($translate.getTranslationTable()),
      instance,
      enums: enums,
      timeNow: moment().format(),
      urlParams: $transition$.params(),
      timeZone: moment.tz.guess(),
      alertConfs
    }, Restangular, $localStorage);
  }
}
