import template from "./filter-container.html";

export function FilterContainerDirective() {
  "ngInject";

  return {
    restrict: "E",
    transclude: true,
    scope: {
      search: "&",
      searchFilterOpen: "="
    },
    template
  };
}
