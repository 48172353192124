import template from "./pagination.html";
import { range } from "lodash";

const MAX_DISPLAYED_PAGES = 5;
const FIRST_PAGE = 1;
const n = Math.floor(MAX_DISPLAYED_PAGES / 2);

// NOTE: works only when MAX_DISPLAYED_PAGES is odd and 5 or more
export function PaginationDirective() {
  "ngInject";

  return {
    restrict: "E",
    scope: {
      itemCount: "=",
      itemsPerPage: "=",
      currentPageNumber: "=",
      totalPageCount: "=",
      cb: "=" //callback // NOT REQUIRED
    },
    link: (scope) => {
      scope.currentPageNumber = FIRST_PAGE;
      scope.pageNumbers = [1];

      scope.getPageNumbers = () => {
        const totalPageCount = scope.totalPageCount;
        const currentPage = scope.currentPageNumber;

        if (totalPageCount <= MAX_DISPLAYED_PAGES) {
          return range(1, 1 + totalPageCount);
        } else if (n >= currentPage - n && currentPage + n < totalPageCount) {
          return range(1, 1 + MAX_DISPLAYED_PAGES);
        } else if (currentPage + n + 1 >= totalPageCount) {
          return range(totalPageCount - MAX_DISPLAYED_PAGES + 1, totalPageCount + 1);
        } else if (n < currentPage - n && currentPage + n < totalPageCount) {
          const start = currentPage - n;
          return range(start, start + MAX_DISPLAYED_PAGES);
        }
        return [];
      };

      scope.previousPage = () => scope.select(scope.currentPageNumber - 1);

      scope.nextPage = () => scope.select(scope.currentPageNumber + 1);

      scope.select = (num) => {
        scope.currentPageNumber = num;
        if (scope.cb) {
          scope.cb(num);
        }
      };
    },
    template
  };
}
