const EMAIL_REGEXP = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

export function EmailValidatorDirective() {
  "ngInject";

  return {
    require: "?ngModel",
    link: function(_, __, ___, ctrl) {
      // only apply the validator if ngModel is present and Angular has added the email validator
      if (ctrl && ctrl.$validators.email) {
        // this will overwrite the default Angular email validator
        ctrl.$validators.email = function(modelValue) {
          return EMAIL_REGEXP.test(modelValue);
        };
      }
    }
  };
}
