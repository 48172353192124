import moment from "moment";
import { times, reduce } from "lodash";
import angular from "angular";

const FIVE_MINS_AS_MILLIS = moment.duration(5, "minutes").asMilliseconds();
const NUM_5MINS_IN_6_HOURS = 6 * 12;

export function BarChartDirective($window, $filter) {
  "ngInject";

  return {
    restrict: "E",
    scope: {
      data: "<"
    },
    link: (scope, element) => {
      const createTimeframes = (fromTimestamp, toStamp) =>
        times(calculateTimeSlots(fromTimestamp, toStamp),
          i => createTimeframe(fromTimestamp.clone().add(i * 5, "minute")));

      const calculateTimeSlots = (fromStamp, toStamp) => Math.floor(toStamp.diff(fromStamp, "minutes") / 5);

      const createTimeframe = fromTimestamp => ({
        timestamp: fromTimestamp.valueOf()
      });

      const roundTo5Mins = timestamp =>
        moment(Math.ceil(timestamp.valueOf() / FIVE_MINS_AS_MILLIS) * FIVE_MINS_AS_MILLIS);

      const findRoundMax = n => {
        if (n >= 1000) {
          return n; // numeraljs handles numbers over 1000
        }
        const powerMap = [0, 1, 1, 2];
        return Math.ceil((n / (Math.pow(10, powerMap[n.toString().length])))) *
          (Math.pow(10, powerMap[n.toString().length]));
      };

      const getTimestamp = i => {
        const toStamp = roundTo5Mins(moment());
        const fromStamp = toStamp.clone().subtract(NUM_5MINS_IN_6_HOURS * 5, "minutes");
        const slots = createTimeframes(fromStamp, toStamp);
        return slots[i].timestamp;
      };

      const getTimestampLabel = timestamp => {
        return moment(timestamp).format("HH:mm");
      };

      const isStartOfHour = timestamp => {
        return moment(timestamp).minute() === 0;
      };

      const redraw = (newValue) => {
        element.empty();

        const labelHeight = 20;
        const width = element.parent()[0].offsetWidth;
        const height = element.parent()[0].offsetHeight;

        const chartPadding = 40;
        const chartH = height - labelHeight;
        const chartW = width - chartPadding;

        if (newValue && newValue.length > 0) {
          const max = reduce(newValue, (max, data) => {
            const total = data.success + data.failure + data.unknown;
            return Math.max(total, max);
          }, 0);
          const roundedMax = findRoundMax(max);

          let template = `<svg viewBox="0 0 ${width} ${height}">`;

          const maxSlots = NUM_5MINS_IN_6_HOURS;
          let barNum = 0;
          times(maxSlots, i => {
            const value = newValue.find(d => d.timestamp === getTimestamp(i));
            if (value) {
              const volume = value.success + value.failure + value.unknown;

              const failureBarHeight = roundedMax > 0 ? (chartH - chartPadding) / roundedMax * value.failure : 0;

              const unknownBarHeight = roundedMax > 0 ? (chartH - chartPadding) / roundedMax * value.unknown : 0;
              const unknownBarOffset = failureBarHeight;

              const successBarHeight = roundedMax > 0 ? (chartH - chartPadding) / roundedMax * value.success : 0;
              const successBarOffset = unknownBarOffset + unknownBarHeight;

              template += "<g>";
              template += `<rect class="area--failure barchart-bar-${barNum}"
                                 x="${chartPadding + chartW / maxSlots * i + 1}"
                                 y="${chartH - failureBarHeight}"
                                 height="${failureBarHeight}"
                                 width="${chartW / maxSlots - 1}" />`;

              template += `<rect class="area--unknown barchart-bar-${barNum}"
                                 x="${chartPadding + chartW / maxSlots * i + 1}"
                                 y="${chartH - unknownBarHeight - unknownBarOffset}"
                                 width="${chartW / maxSlots - 1}"
                                 height="${unknownBarHeight}" />`;

              template += `<rect class="area--success barchart-bar-${barNum}"
                                 x="${chartPadding + chartW / maxSlots * i + 1}"
                                 y="${chartH - successBarHeight - successBarOffset}"
                                 width="${chartW / maxSlots - 1}"
                                 height="${successBarHeight}" />`;
              template += `<title>${volume}</title>`;
              template += "</g>";

              if (isStartOfHour(value.timestamp)) {
                template += `<line x1="${chartW / maxSlots * i}"
                             x2="${chartW / maxSlots * i}"
                             y1="0"
                             y2="${chartH}"
                             stroke="#e0e0e0"
                             stroke-width="1"/>`;
                const timestampLabel = getTimestampLabel(value.timestamp);
                template += `<text style="font-size: 12px"
                                   x="${chartW / maxSlots * i}"
                                   y="${height}"
                                   fill="black">${timestampLabel}</text>`;
              }

              barNum++;
            }
          });

          // DRAW GRID
          if (roundedMax > 0) {
            template += `<line x1="${chartPadding}"
                             x2="${width}"
                             y1="${chartPadding}"
                             y2="${chartPadding}"
                             stroke="#e0e0e0"
                             stroke-width="1"/>`;

            template += `<line x1="${chartPadding}"
                             x2="${width}"
                             y1="${(chartH + chartPadding) / 2}"
                             y2="${(chartH + chartPadding) / 2}"
                             stroke="#e0e0e0"
                             stroke-width="1"/>`;

            template += `<text style="font-size: 12px;"
                             x="${chartPadding - 5}"
                             y="${chartPadding}"
                             alignment-baseline="middle"
                             text-anchor="end">${$filter("numeraljs")(roundedMax, "0[.]0a")}</text>`;

            template += `<text style="font-size: 12px;"
                             x="${chartPadding - 5}"
                             y="${(chartPadding + chartH) / 2}"
                             alignment-baseline="middle"
                             text-anchor="end">${$filter("numeraljs")(Math.round(roundedMax / 2), "0[.]0a")}</text>`;
          } else {
            template += `<line x1="${chartPadding}"
                             x2="${width}"
                             y1="${chartPadding}"
                             y2="${chartPadding}"
                             stroke="#e0e0e0"
                             stroke-width="1"/>`;

            template += `<text style="font-size: 12px;"
                             x="${chartPadding - 5}"
                             y="${chartPadding}"
                             alignment-baseline="middle"
                             text-anchor="end">0</text>`;

            template += `<text x="${chartW / 2 + chartPadding / 2}" y="${chartH / 2}" alignment-baseline="middle"
                             text-anchor="middle" fill="#ccc">No data.</text>`;
          }

          template += "</svg>";
          element.append(template);
        }
      };

      scope.$watch("data", redraw);
      angular.element($window).bind("resize", () => redraw(scope.data));
    }
  };
}
