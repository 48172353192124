import "angular";
import "angular-animate";
import "angular-aria";
import "angular-cookies";
import "angular-material-icons";
import "angular-material-icons/angular-material-icons.css";
import "angular-messages";
import "angular-moment";
import "angular-numeraljs";
import "angular-sanitize";
import "angular-translate";
import "angular-toastr";
import "@uirouter/angularjs";
import "angularjs-datetime-picker";
import "restangular";
import "rx-angular";
import "ngstorage";
