const trxFi = {
  "transaction-identifiers": "Tunnisteet",
  "transaction-details": "Tapahtuman tiedot",
  "transaction-flow": "Tapahtumaketju",
  "transaction-metadata": "Metatiedot",
  "transactions-no-applications-selected": "Ole hyvä ja valitse vähintään yksi applikaatio.",
  "transaction-error-type": "Tyyppi",
  "transaction-error-path": "Polku",
  "transaction-error-kind": "Laatu",
  "transaction-error-value": "Arvo",
  "transaction-error-message": "Virhe",
  "transaction-error-batch": "Erä",
  "transaction-error-errors": "Virheet",
  "transaction-error-warnings": "Varoitukset",
  "transaction-error-error": "Virhe",
  "transaction-error-warning": "Varoitus",
  "transactions-timeout": "Hakuaika loppui. Ole hyvä, ja käytä pienempää aikaväliä.",
  "transactions-status": "Status",
  "flow-status": "Flow Status",
  "transactions-last-status": " Last Status",
  "transactions-from": "Mistä",
  "transactions-to": "Mihin",
  "transactions-integrationSegment": "Integraation Tyyppi",
  "transactions-payloadType": "Tyyppi",
  "transactions-date-time": "Päivä & Aika",
  "transaction-operation": "Operaatio",
  "transaction-message": "Viesti",
  "transaction-success-kind": "Onnistunut",
  "transaction-unknown-kind": ", jonka tilaa ei tiedetä",
  "transaction-failure-kind": "Epäonnistunut",
  "transactions-containing": ", joka sisältää",
  "transaction": "tapahtuma",
  "transaction-flow-id": "Tapahtumaketjun Id",
  "transaction-api-warnings": "API varoitukset",
  "transaction-flow-failed": "Yhteys virhe",
  "failed-to-search-timeline": "Failed to search for IDs."
};

export { trxFi };
