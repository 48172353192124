export class BaseService {
  constructor() {
    "ngInject";
  }

  plainData(request) {
    return new Promise((resolve, reject) => {
      if (!request.then) {
        reject("Request is not thenable!");
      }

      request
        .then((result) => {
          resolve(result ? result.plain() : null);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
