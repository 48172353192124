import { Elm } from "../../../../../aino.elmproj";
import { createElmApp } from "../../../utils/ElmUtils";
import moment from "moment-timezone";

export class ElmTransactionExportController {
  constructor($scope,
              $rootScope,
              $state,
              $transition$,
              $location,
              $localStorage,
              $translate,
              instance,
              enums,
              Restangular) {
    "ngInject";

    createElmApp($rootScope, $state, Elm.Pages.Instance.TransactionExport.Page, "elm-trx-export", {
      token: $localStorage.accessToken || null,
      translations: JSON.stringify($translate.getTranslationTable()),
      instance,
      enums: enums,
      timeNow: moment().format(),
      timeZone: moment.tz.guess()
    }, Restangular, $localStorage);
  }
}
