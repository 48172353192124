export class AccountService {
  constructor(Restangular) {
    "ngInject";
    this.account = Restangular.service("account");
  }

  create(account) {
    return this.account.one().customPOST(account);
  }
}

