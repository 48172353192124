import { BaseService } from "./base.service";

export class IdentifiedFlowService extends BaseService {
  constructor(Restangular) {
    "ngInject";

    super();

    this.instance = Restangular.service("instance");
  }

  getShowInAlertsFlows(instanceId) {
    return super.plainData(
      this.getPath(instanceId)
        .one("showInAlerts")
        .get()
    );
  }

  getShowInFiltersFlows(instanceId) {
    return super.plainData(
      this.getPath(instanceId)
        .one("showInFilters")
        .get()
    );
  }

  getPath(instanceId) {
    return this.instance.one(instanceId).one("identifiedFlow");
  }
}
