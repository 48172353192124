const fi = {
  "login-button": "Kirjaudu",
  "login-h2": "Kirjautuminen",
  "login-password": "Salasana",
  "login-forgot-password": "Unohtuiko salasana?",
  "login-register": "Luo Käyttäjätiedot",
  "login-create-account": "Luo uusi asiakkuus",
  "login-new-to-aino": "New to Aino.io?",
  "password-recovery-title": "Salasanan Palautus",
  "password-recovery-email": "Sähköposti",
  "password-recovery-submit-button": "Lähetä",
  "password-recovery-success": "Sinulle on lähetetty sähköpostia antamaasi osoitteeseen.",
  "password-recovery-failed": "Palautussähköpostin lähetys epäonnistui.",
  "password-reset-title": "Anna uusi salasana",
  "password-reset-1": "Salasana",
  "password-reset-2": "Uudestaan",
  "password-reset-submit-button": "Lähetä",
  "password-reset-success": "Salasanasi on nyt vaihdettu. Kirjaudu sisään.",
  "password-reset-login": "Kirjautumissivulle",
  "password-reset-failed": "Salasanan vaihto epäonnistui. Yritä uudelleen.",
  "instance-from": "Alkaen",
  "instance-to": "Päättyen",
  "instance-identifier": "Tunniste",
  "instance-search": "Haku",
  "instance-timestamp-error": "väärä aikamuoto",
  "instance-timestamp-later-error": "väärä aikamuoto",
  "instance-filter-failure": "Virheelliset",
  "instance-filter-all": "Valitse kaikki",
  "instance-filter-success": "Onnistuneet",
  "instance-filter-unknown": "Tuntemattomat",
  "instance-filter-select-at-least-one-operation": "Ole hyvä ja valitse ainakin yksi operaatio.",
  "instance-filter-select-at-least-one-integrationSegment": "Ole hyvä ja valitse ainakin yksi integration segment.",
  "instance-filter-select-at-least-one-application": "Ole hyvä ja valitse ainakin yksi applikaatio.",
  "instance-filter-select-at-least-one-idType": "Ole hyvä ja valitse ainakin yksi id tyyppi.",
  "instance-filter-select-at-least-one-metadataName": "Ole hyvä ja valitse ainakin yksi metadata nimi.",
  "instance-no-transactions": "Ei transaktioita.",
  "instance-no-errors": "Ei virheitä.",
  "instance-no-warnings": "Ei varoituksia.",
  "instance-clear-errors": "Tyhjennä lista",
  "instance-clear-warnings": "Tyhjennä lista",
  "instance-errors-clearing-failed": "Virheiden poisto epäonnistui.",
  "instance-transaction-capacity": "tilasta käytetty",
  "instance-stats__last-trx-timestamp": "haettavia transaktioita",
  "instance-stats__last-summary-timestamp": "historiallista dataa saatavilla",
  "instance-no-transactions-available": "Valitulle aikavälille ei löydy transaktioita.",
  "instance-search-help": "Käytä lainausmerkkejä jos hakusana sisältää välilyöntejä.",
  logout: "Poistu",
  "main-navigation-home": "Koti",
  "logged-in-as": "Kirjautunut käyttäjä",
  "date-and-time": "Päivämäärä ja aika",
  "apikey-description-input": "Kuvaus uudelle API-avaimelle",
  "api-key-management-title": "Transaktio API pääsy",
  "api-key-management-description": "Kuvaus",
  "api-key-management-apiKey": "API-Avain",
  "api-key-management-manage": "Käsittele",
  "api-key-management-enable": "Päälle",
  "api-key-management-disable": "Pois päältä",
  "api-key-management-disabled": "(Disabloitu)",
  "api-key-management-creation-failure": "API-avaimen luonti epäonnistui.",
  "api-key-management-toggle-failure": "API-avaimen togglaus epäonnistui.",
  "api-key-management-toggle-success": "OK! Muutos tulee voimaan seuraavan 30 minutiin kuluessa.",
  "api-key-management-import": "Allow Import",
  "api-key-management-export": "Allow Export",
  "api-key-management-toggle-import-failure": "Failed to toggle the API key's allow import status.",
  "api-key-management-toggle-export-failure": "Failed to toggle the API key's allow export status.",
  "api-key-management-user-group-change-failed": "Failed to changed user group.",
  "connection-lost-error": "Yhteys katkesi, yritäppä uudestaan joskus.",
  "no-access-error": "Pääsy kielletty",
  "invalid-credentials-errors": "Väärät paperit",
  "not-confirmed-login-error": "Tilin aktivointi puuttuu. Sähköpostiisi on lähetetty uusi aktivointiviesti.",
  "api-key-management__description-input-warning": "Varoitus! Kuvaus on jo olemassa.",
  "forbidden.title": "Pääsy evätty",
  "forbidden.help-text": "Sinulla ei ole oikeuksia nähdä tätä sisältöä.",
  "forbidden.main-view-link": "Palaa etusivulle",
  "flash-error.not-logged-in": "Ole hyvä ja kirjaudu sisään nähdäksesi suojattua sisältöä.",
  "instance-filter-clear": "Tyhjennä valinnat",
  "instance-filter-applications": "Applikaatiot",
  "instance-filter-status": "Status",
  "instance-header-overview": "Yleiskatsaus",
  "instance-header-timeline": "Aikajana",
  "instance-header-flowtimeline": "Flow Aikajana",
  "instance-header-operations": "Operaatiot",
  "instance-header-transactions": "Transactiot",
  "instance-header-applications": "Applikatiot",
  "instance-header-alerts": "Hälyt",
  "instance-header-errors": "Virheet",
  "instance-header-api-access": "API Hallinta",
  "instance-header-export": "Export",
  "instance-header-flow-export": "Flow Export",
  "instance-header-alert-export": "Alert Export",
  "instance-header-users": "Käyttäjät",
  "instance-header-months-days": "{{months}}kk {{days}}pv",
  "instance-header-days": "{{days}}pv",
  "instance-header-transactions-since": "{{ts}} lähtien",
  "instance-header-flows": "Flow:t",
  "instance-header-identifiedflows": "Identified Flows",
  "instance-header-linkedflows": "Linked Flows",
  "instance-header-transaction-export": "Transaction Export",
  "instance-analyzed-count": "Analysoitua tapahtumaa",
  "instance-transaction-count": "Haettavissa olevat tapahtumat",
  "application-stats-volume": "Voluumi",
  "application-stats-status-ratios": "Statuksien suhteet",
  "instance-time-year": "Vuosi",
  "instance-time-month": "Kuukausi",
  "instance-time-week": "Viikko",
  "instance-time-day": "Päivä",
  "instance-time-hour": "Tunti",
  "instance-start-from": "Alkaen",
  "instance-time-reset": "Nyt",
  "confirm-title": "Kiitoksia tilin aktivoinnista",
  "confirm-text": "Nyt voit käyttää Aino.io.",
  "confirm-login": "Sisäänkirjautumissivulle.",
  "confirm-failed-text": "Virheellinen verifiointilinkki",
  "already-confirmed-title": "Tilisi on jo aktivoitu.",
  "already-confirmed-text": "Kirjaudu sisään.",
  "dashboard-no-content": "Sinulla ei ole vielä oikeuksia yhteenkään instanssiin. Ole yhteydessä pääkäyttäjään.",
  "dashboard-pending-invitations": "Instanssi Kutsut",
  "logout-failed": "Uloskirjautuminen epäonnistui. Yritä pian uudelleen.",
  "data-not-analyzed": "Dataa ei ole vielä analysoitu.",
  "timeline-search-placeholder": "ID Haku",
  "timeline-search-results-not-found": "Hakutuloksia ei löytynyt.",
  "failed-to-get-flow-volume": "Failed to get flow volume data.",
  "signup-for-newsletter": "Kyllä, haluan liittyä Aino.io:n sähköpostilistalle",
  "signup-for-tech-newsletter": "Kyllä, haluan liittyä Aino.io:n tekniselle sähköpostilistalle",
  "newsletter-unsubscribe": "You can unsubscribe at any time.",
  "accept-invitation": "Hyväksy",
  "reject-invitation": "Hylkää",
  "invitation-reponse-failed": "Kutsun vastaaminen epäonnistui.",
  "invitation-reponse-accepted": "Kutsu onnistuneesti hyväksytty.",
  "invitation-reponse-rejected": "Kutsu onnistuneesti hylätty.",
  "tos-checkbox": "Olen lukenut ja hyväksyn käyttöehdot.",
  "tos-submit": "Lähetä",
  "timeline-filter-search": "Tarkenna hakua",
  "timeline-transaction-status": "Transaction status",
  "timeline-filter-operations": "Operations",
  "timeline-filter-integrationSegments": "Integration Segments",
  "timeline-filter-applications": "Applications",
  "timeline-filter-idTypes": "Id Types",
  "timeline-filter-metadataNames": "Metadata Names",
  "alert-summary-enum-operation": "Selected Operations",
  "alert-summary-enum-application": "Selected Applications",
  "error-details": "Error Details",
  "warning-details": "Warning Details",
  "problematic-relative-failures": "Relative Failures",
  "problematic-failure-volume": "Failure Volume",
  "problematic-failure-rate": "Failure Rate",
  "problematic-relative-failures-text": "{{ratio}}% of all monitored failures",
  "problematic-failure-volume-text": "{{volume}} monitored failures",
  "problematic-failure-rate-text": "{{rate}}%",
  "overview-problematic-applications": "Problematic Applications",
  "overview-problematic-operations": "Problematic Operations",
  "overview-bar-chart-text": '',
  "overview-overall-status-ratios": "6-hour Overview of Monitored Integrations",
  "problematic-application-relative-failures-header": "Applications most involved in transaction failures",
  "problematic-application-failure-volume-header": "Applications with highest transaction failure volume",
  "problematic-application-failure-rate-header": "Applications with highest failure rate",
  "problematic-operation-relative-failures-header": "Operations most involved in transaction failures",
  "problematic-operation-failure-volume-header": "Operations with highest transaction failure volume",
  "problematic-operation-failure-rate-header": "Operations with highest failure rate",
  "overview-no-data-yet": "No data collected yet.",
  "applications-network-title": "Application Network",
  "applications-volumes": "Application Volume",
  "operations-volumes": "Operation Volume",
  "instance-operation": "Operation",
  "applications-no-data": "No data available.",
  "operations-no-data": "No data available.",
  "overview-volume-percentage": "{{value}} Volume",
  "overview-success-percentage": "{{value}}% Success",
  "overview-failure-percentage": "{{value}}% Failure",
  "overview-unknown-percentage": "{{value}}% Unknown",
  "overview-time-text": "From {{startTime}} to {{endTime}}",
  "save-filters-input-placeholder": "Save selected filters...",
  "save-filters-remove-button": "Poista",
  "timeline-loaded-filter": "Ladattu filteri: {{filter}}",
  "errors-settings-enable": "Enable custom times",
  "error-settings-past-warning": "Minutes in the past to generate a warning:",
  "error-settings-future-warning": "Minutes in the future to generate a warning:",
  "error-settings-past-error": "Minutes in the past to generate an error:",
  "error-settings-future-error": "Minutes in the future to generate an error:",
  "error-settings-saved": "Settings saved.",
  "error-settings-saving-failed": "Failed to save the settings.",
  "error-settings-save": "Save",
  "timeline-max-value-placeholder": "TODO",
  "flow-step": "Askel",
  "flow-name": "Flow Nimi",
  "flow-from-title": "From",
  "flow-to-title": "To",
  "flow-operation-title": "Operation",
  "flow-add-step": "Add Step",
  "flow-save": "Save",
  "flow-cancel": "Cancel",
  "flow-header": "Flow Definition",
  "flow-help":
    "Use the inputs below to define how a certain flow should look like. The definition can then be used in Alerts, for example.",
  "flow-name-input": "Flow name",
  "flows-new-flow": "New Flow Configuration",
  "flows-table-name": "Name",
  "flows-table-start": "Starting Application",
  "flows-table-end": "Ending Application",
  "flows-table-remove": "Remove",
  "flows-table-remove-question": "Really remove the flow configuration?",
  "flows-duplicate-extension": "(Duplicate)",
  "flows-remove-failed": "Failed to remove flow configuration.",
  "flows-remove-success": "Successfully removed the flow configuration.",
  "flows-duplicate-failed": "Failed to duplicate flow configuration.",
  "flows-duplicate-success": "Successfully duplicated the flow configuration.",
  "send-flow-failed": "Failed to create flow configuration.",
  "flow-update": "Update",
  "update-flow-failed": "Flow configuration update failed.",
  "flow-delete-step-button": "Remove",
  "check-duplicate-flow-failed": "Failed to check for duplicates",
  "flow-has-duplicate":
    "There is already a Flow configuration defined with either a same name or a combination of applications or operations.",
  "timeline-show-totals": "Show the totals on the bars",
  "trx-list-save-csv": "Save as csv",
  "trx-list-save-help": "This will save the whole list as a CSV file.",
  "failed-to-get-flows": "Failed to get flows.",
  "failed-save-flows": "Failed to save flows.",
  "failed-to-get-transactions": "Failed to get transactions.",
  "failed-save-transactions": "Failed to save transactions.",
  "toggle-table-to-flow": "Show Flows",
  "toggle-table-to-trx": "Show Transactions",
  "flowtimechart-max-value": "Use custom maximum time for the chart:",
  "flowtimechart-minutes": "minutes",
  "flowtimechart-seconds": "seconds",
  "identified-flow-manage": "Manage",
  "named-flows-load-failed": "Failed to load named flows.",
  "flow-lead-time": "Lead Time"
};

export { fi };
