export default function sceStrategy($sce) {
  "ngInject";

  return (value, mode) => {
    if (mode === "text") {
      const result = $sce.trustAsHtml(value);
      return result.$$unwrapTrustedValue ? result.$$unwrapTrustedValue() : result;
    }
    return value;
  };
};
